.paragraphs-item-chapter-title {
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 15px;
    color: $color-white;
    @include breakpoint($screen-s) {
      padding: 50px 30px;
      flex-wrap: nowrap;
    }
    .field-name-field-chapter-number {
      padding-left: 5px;
      padding-right: 30px;
      width: 10%;
      @include breakpoint($screen-m) {
        padding-left: 20px;
      }
      @include breakpoint($screen-xl) {
        padding-right: 80px;
      }
      .field-label {
        width: 75px;
        margin-left: auto;
        margin-right: auto;
        border-bottom: 2px dotted $color-white;
        color: $color-white;
        font-size: 10pt;
        font-weight: 800;
        line-height: 24px;
        padding-bottom: 10px;
        text-align: center;
        text-transform: uppercase;
      }
      .field-items {
        display: flex;
        justify-content: center;
        font-size: 116px;
        line-height: 130px;
        font-family: $font-oswald;
        font-weight: 600;
        text-shadow:
          -1px -1px 0 $color-white,
          1px -1px 0 $color-white,
          -1px 1px 0 $color-white,
          1px 1px 0 $color-white;
        @include breakpoint($screen-m) {
          font-size: 145px;
          line-height: 150px;
        }
        @include breakpoint($screen-l) {
          font-size: 183px;
          line-height: 183px;
        }
      }
    }
    .group-chapter-overview {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      border-top: $color-white solid 5px;
      padding-top: 20px;
      @include breakpoint($screen-s) {
        width: 84%;
        margin-left: auto;
        margin-right: auto;
      }
      @include breakpoint($screen-m) {
        flex-direction: row;
        margin-left: 40px;
      }
      @include breakpoint($screen-l) {
        margin-left: 0;
      }
      .field-name-field-chapter-title {
        font-size: 36px;
        line-height: 40px;
        font-weight: 900;
        padding-bottom: 20px;
        padding-right: 15px;
        @include breakpoint($screen-m) {
          width: 45%;
          box-sizing: border-box;
          padding-right: 50px;
        }
        @include breakpoint($screen-l) {
          font-size: 50px;
          line-height: 60px;
        }
      }
      .field-name-field-chapter-description {
        font-size: 26px;
        line-height: 30px;
        font-weight: lighter;
        font-family: $font-maitree;
        @include breakpoint($screen-m) {
          font-size: 35px;
          line-height: 40px;
          width: 60%;
        }
        @include breakpoint($screen-l) {
          font-size: 50px;
          line-height: 55px;
        }
      }
    }
  }
  &.background-color-red {
    .field-name-field-chapter-number {
      .field-items {
        color: $color-red;
      }
    }
  }
  &.background-color-light_red {
    .field-name-field-chapter-number {
      .field-items {
        color: $color-red-light;
      }
    }
  }
  &.background-color-blue {
    .field-name-field-chapter-number {
      .field-items {
        color: $color-blue;
      }
    }
  }
  &.background-color-green {
    .field-name-field-chapter-number {
      .field-items {
        color: $color-green;
      }
    }
  }
  &.background-color-olive {
    .field-name-field-chapter-number {
      .field-items {
        color: $color-olive;
      }
    }
  }
  &.background-color-purple {
    .field-name-field-chapter-number {
      .field-items {
        color: $color-purple;
      }
    }
  }
  &.background-color-orange {
    .field-name-field-chapter-number {
      .field-items {
        color: $color-orange;
      }
    }
  }

}
