.block-mailchimp-signup-form {
  background-color: $color-white;
  .content {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 15px;
    @include breakpoint($screen-s) {
      padding: 50px 30px;
    }
  }

  .mailchimp-block-inner-wrapper {
    @include breakpoint($screen-s) {
      width: 84%;
      margin-left: auto;
      margin-right: auto;
    }
    @include breakpoint($screen-m) {
      margin-left: 40px;
    }
    @include breakpoint($screen-l) {
      margin-left: 0;
    }
  }

  h5 {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .mc-field-group {
    margin-bottom: 15px;
    &.input-group {
      > strong {
        @include field-label-red;
        font-size: 11px;
      }
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 15px;
        li {
          input {
            width: auto;
            margin-right: 10px;
          }
          label {
            display: inline;
            color: $color-gray;
            font-family: $font-muli;
            font-size: 13px;
            line-height: $base-line-height;
            text-transform: none;
            font-weight: normal;
          }
        }
      }
    }
  }

  input:not([type="submit"]) {
    width: 570px;
  }
}

#mce-success-response {
  padding: 20px 20px 40px 0;
  font-weight: bold;
}
