%paragraph-one-col-with-gutter {
  display: flex;
  position: relative;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  > .content {
    padding-left: 15%;
    width: 72%;
    padding-right: 13%;
    @include breakpoint($screen-m) {
      padding-left: 30%;
      width: 52.5%;
      padding-right: 17.5%;
    }
    @include breakpoint($screen-l) {
      padding-left: 40%;
      width: 42.5%;
      padding-right: 17.5%;
    }
    hr:after {
      content: '';
      position: absolute;
      top: 0;
      right: -41.18%;
      width: 41.18%;
      height: 4px;
      background-color: $color-red;
    }
  }
}
