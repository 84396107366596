.view-mode-teaser-node-type-resource-detail {
  background-color: $color-white;
  position: relative;
  padding: 15px;

  &.esyb-curriculum .field-name-title-field {
    margin-top: 10px;
  }

  .type-banner {
    position: absolute;
    width: 100%;
    color: $color-white;
    background-color: $color-purple;
    top: 0;
    left: 0;
    text-align: center;
    font-size: 10px;
    line-height: 18px;
    font-weight: normal;
    text-transform: uppercase;
  }

  .field-name-title-field {
    margin-top: 0;
    margin-bottom: 10px;
    width: 100%;
    a {
      font-family: $font-maitree;
      font-size: 18px;
      font-weight: 600;
      color: $color-black;
      text-decoration: none;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
  }

  .group-description {
    display: flex;
    padding-bottom: 5px;
  }

  .field-name-field-lesson-summary {
    padding-bottom: 10px;
    span {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .field-name-field-teaser-image {
    display: none;
    width: 100px;
    height: 100px;
    padding: 6px 10px 0 0;
    flex-shrink: 0;
    overflow: hidden;
    @include breakpoint($screen-m) {
      display: block;
    }
    img {
      display: block;
    }
  }

  .group-resource-info {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-top: 10px;
    @extend %horizontal-polka-dots;
    background-position: top;
  }

  .field-name-field-grade-level-select,
  .progression-count,
  .pseudo-standards-aligned {
    width: 33.33%;
    text-align: center;
    .field-label {
      text-transform: uppercase;
      font-family: $font-muli;
      font-size: 10px;
      line-height: 18px;
      font-weight: 800;
      letter-spacing: 1px;
    }
    .field-item {
      @extend h6;
    }
  }

  .field-name-field-grade-level-select {
    margin-left: 0;
    .field-items {
      display: flex;
      justify-content: center;
    }
  }

  .progression-count {
    margin-left: 33.333%;
  }

  .field-name-field-grade-level-select + .progression-count {
    margin-left: 0;
  }

  .pseudo-standards-aligned {
    min-height: 40px;
    .field-item {
      margin: 0 auto;
    }
  }

  ul.inline {
    display: none;
  }
}
