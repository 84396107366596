#crm-container.crm-public,
#crm-profile-block.crm-public {
  input[type="text"],
  input[type="password"],
  input[type="email"],
  select,
  .crm-form-textarea {
    border-radius: 0;
    @extend .form-text;
    background-image: none;
    height: auto;
  }
  .select2-container .select2-choice {
    border-radius: 0;
    background-image: none;
    background-color: $color-gray-lighter;
  }
  label {
    @include field-label-red;
    font-size: 11px;
  }
  .crm-form-checkbox + label,
  .crm-form-radio + label,
  .price-set-row label {
    color: $color-gray;
    font-family: $font-muli;
    font-size: 13px;
    line-height: $base-line-height;
    text-transform: none;
    font-weight: normal;
    padding-left: 3px;
  }
  .soft_credit_type_id-section .crm-form-radio + label {
    padding-right: 20px;
  }
  legend {
    @extend %h3;
    margin-left: 15px;
  }
  .crm-button,
  input.crm-form-submit {
    @include button;
    text-shadow: none;
  }
  .crm-i-button > .crm-i {
    top: .9em;
    left: .8em;
  }
  #crm-submit-buttons {
    margin-bottom: 30px;
    @include clearfix;
  }
}
.page-civicrm-contribute .l-content-wrapper {
  margin: 0 auto;
  max-width: 690px;
  #content .section {
    padding: 0px 30px;
  }
  .crm-section {
    .label {
      width: 32%;
    }
    .content {
      margin-left: 35%;
    }
  }
  /*hide honoree email, gr issue #32577*/
  .honoree-name-email-section {
    .editrow_email-1-section {
      display: none;
    }
  }
}
