.view-mode-search-result-node-type-program {
  .content .field-name-title-field {
    margin-bottom: 0;
  }
  .field-name-field-program-address {
    .field-item {
      display: flex;
      font-size: 11px;
      letter-spacing: 1px;
      color: $color-gray-light;
      font-weight: 600;
      .country {
        padding-left: 4px;
      }
    }
  }
}
