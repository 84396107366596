#footer {
  background-color: $color-white;
  text-align: center;
  padding: 40px 10px;
  font-size: 12px;
  line-height: 16px;

  .footer__social {
    ul {
      padding: 0px;
      margin-bottom: 50px;
    }
    li {
      display: inline;
      list-style-type: none;
      padding: 0px 10px;

      @include breakpoint($screen-s) {
        padding: 0px 16px;
      }
    }
    img {
      vertical-align: top;
    }
  }
  .footer__nav {
    padding: 2px 0px 25px;
    line-height: 25px;

    ul.menu {
      text-align: center;
      padding: 0px;

      li {
        display: inline;
        list-style-type: none;
        margin: 0px;
        padding: 0px 4px;

        @include breakpoint($screen-s) {
          padding: 0px 35px;
        }

        a {
          @extend %link-default;
          font-weight: 800;
          text-transform: uppercase;
          text-decoration: none;
          border-bottom: solid 1px;
          padding-bottom: 5px;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .footer__website-credits  {
    p {
      margin-bottom: 0px;
    }
  }
}
