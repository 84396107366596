.paragraphs-item-one-column-paragraph {
  @extend %paragraph-one-col-with-gutter;
  line-height: 1.7;
  .content {
    overflow-x: hidden;
    overflow-y: visible;
  }
  .field-name-field-body {
    p {
      &:first-child:first-letter {
        color: $color-red;
        float: left;
        font-size: 95px;
        font-weight: 800;
        line-height: 77px;
        padding-right: 10px;
      }
    }
  }
}
.page-background-color-cream .paragraphs-item-one-column-paragraph .field-name-field-body {
  p:first-child:first-letter {
    color: $color-green-darkest;
  }
}
