.paragraphs-item-call-to-action-three-column {
  .content {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    padding: 15px;
    @include breakpoint($screen-m) {
      flex-direction: row;
    }
    .group-column {
      @include breakpoint($screen-m) {
        width: 33%;
      }
    }
  }
  .group-middle-column {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 45px 25px;
    margin: 20px 0;
    @include breakpoint($screen-m) {
      margin: 0 20px 8px 20px;
      padding: 30px 25px;
    }
    @include breakpoint($screen-l) {
      padding: 80px 40px;
    }
  }
  .field-name-field-title {
    font-family: $font-maitree;
    color: $color-green-darkest;
    font-size: 35px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    @include breakpoint($screen-m) {
      font-size: 45px;
    }
    @include breakpoint($screen-l) {
      font-size: 55px;
      line-height: 1.55;
    }
    @include breakpoint($screen-xl) {
      font-size: 70px;
    }
  }
  .field-name-field-link {
    margin-top: 30px;
    .field-item {
      display: flex;
      justify-content: center;
    }
    a {
      @include button;
      background-color: $color-orange-medium;
      text-align: center;
      letter-spacing: 2px;
      padding: 15px 30px;
      font-weight: 600;
      &:hover {
        background-color: $color-green-darkest;
      }
    }
  }
  .field-name-field-left-image,
  .field-name-field-right-image {
    .field-items {
      width: 100%;
      margin: 0 auto;
      @include breakpoint($screen-m) {
        height: 100%;
        width: 100%;
        margin: 0;
      }
    }
  }
}
