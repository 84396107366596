.view-mode-badge {
  /* partner node*/
  .field-name-field-partner-logo {
    .field-item {
      box-sizing: border-box;
      padding: 10px;
      border: 1px solid $color-red;
      width: 122px;
    }
  }

  /* program member field collection*/

  a {
    text-decoration: none;
  }

  .field-name-field-program-logo-photo,
  .pseudo-field-program-member-image {
    margin-bottom: 5px;
    img {
      display: block;
      max-width: 200px;
      height: auto;
    }
  }
  .field-name-field-program-member-name,
  .pseudo-field-program-member-name,
  .field-name-title-field {
    text-align: center;
    font-family: $font-maitree;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $color-black;
  }
  .field-name-title-field {
    background: none;
    border-top: none;
    padding: 0;
    a, a:link, a:visited {
      color: $color-black;
      text-decoration: none;
    }
    a:hover {
      color: $color-red;
      text-decoration: none;
    }
  }
  .field-name-field-program-member-title {
    @include field-label-red;
    text-align: center;
  }
}
