.page-node-type-program,
.page-node-type-resource-detail,
.page-node-type-progression-page {
  @include page-container-crimp;
}
.page-node-type-program {
  #page-title {
    @include page-title-with-top-bottom-border;
  }
}
