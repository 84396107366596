.view-standards {
  .view-grouping-header {
    @extend h5;
  }
  .view-grouping-content {
    h3 {
      @extend h6;
      color: $color-black;
    }
  }
  .views-row {
    justify-content: space-between;
    line-height: 1.25;
    @include breakpoint($screen-m) {
      display: flex;
    }
    .views-field-name {
      @extend h6;
      line-height: 1.25;
      margin-top: 2.5px;
      padding-right: 15px;
    }
    .views-field-description {
      width: 90%;
      .field-content p {
        margin: 0;
        font-size: 12px;
      }
    }
  }
}
