.view-mode-sidebar-detail {
  .field-name-field-program-logo-photo,
  .group-address,
  .group-social-links {
    @include dotted-border(single);
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .field-name-field-program-logo-photo {
    padding-top: 0;
    img {
      display: block;
      width: 100%;
      max-width: 315px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .group-address {
    font-size: 13px;
  }
  .group-social-links {
    display: flex;
    .field-formatter-esyp-link-icon {
      margin-right: 10px;
    }
  }
  .group-contact-info {
    @include field-label-red;
    text-align: right;
    line-height: 14px;
    margin-left: auto;

    .field {
      margin-bottom: 25px;

      @include breakpoint($screen-m) {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
