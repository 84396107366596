.pledge-webform.webform-create-a-program {
  .form-checkboxes > .form-type-checkbox {
    margin: 5px 0;
  }
  .webform-component--flexbox--program-type .form-type-checkbox {
    label {
      padding-left: 28px;
      background-repeat: no-repeat;

      &[for="edit-submitted-flexbox-program-type-1"] {
        background-image: url("../images/program-map/icon-garden-classrooms.svg");
      }
      &[for="edit-submitted-flexbox-program-type-2"] {
        background-image: url("../images/program-map/icon-kitchen-classrooms.svg");
      }
      &[for="edit-submitted-flexbox-program-type-3"] {
        background-image: url("../images/program-map/icon-school-cafeterias.svg");
      }
      &[for="edit-submitted-flexbox-program-type-4"] {
        background-image: url("../images/program-map/icon-academic-classrooms.svg");
      }
      &[for="edit-submitted-flexbox-program-type-5"] {
        background-image: url("../images/program-map/icon-support-organizations.svg");
      }
    }
  }
  .webform-component-textarea label {
    font-family: $font-muli;
    font-weight: 800;
    line-height: 20px;
  }
  label.show-label.custom-markup {
    display: block;
    text-align: left;
    margin-top: 15px;
    margin-bottom: -30px;
  }
  .webform-component--would-you-like-to-subscribe-to-our-monthly-email .description {
    margin-left: 26px;
    font-size: 14px;
    color: $color-gray-medium;
  }

  .webform-component-file {
    input#edit-submitted-photo-upload-button {
      font-size: 14px;
      padding: 10px 30px;
    }
  }
  .webform-confirmation {
    h3 {
      color: $color-green;
      font-size: 40px;
    }
    p {
      font-size: 20px;
      font-weight: 700;
    }
    .button {
      background-color: $color-orange-dark;
      font-weight: 500;
    }
  }
  .links {
    display: none;
  }
}
