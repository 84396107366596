.paragraphs-item-video {
  margin: 20px auto;
  max-width: 1200px;

  .field-name-field-video {
    @include breakpoint($screen-m) {
      width: 76%;
      margin-left: 24%;
    }
  }
}
