.program-map-wrapper {
  background-color: $color-white;
  padding-top: 50px;
  padding-bottom: 50px;
}
.program-map {
  overflow:  hidden;
}
.program-map-main {
  border: 1px solid $color-gray-lighter;
}
.program-map-sidebar {
  box-shadow: -2px 0 3px 3px rgba(0, 0, 0, .2);
}
.program-map-program-teaser {
  .node-title {
    &, a:link, a:visited {
      color: $color-black;
      text-decoration: none;
    }
    a:hover {
      color: $color-red;
    }
  }
  &:hover {
    .node-title {
      a:link, a:visited, a:hover {
        color: $color-red;
      }
    }
  }
}
.program-map__program-type-bar {
  flex-wrap: wrap;
  @include breakpoint($screen-m) {
    flex-wrap: none;
  }
}
.program-map__program-type-bar-link {
  padding-left: 55px;
  font-size: 10px;
  line-height: 12px;
  width: 33.333%;
  box-sizing: border-box;

  @include breakpoint($screen-m) {
    width: auto;
  }

  &,
  &:hover,
  &:visited {
    color: $color-gray;
    text-decoration: none;
  }
  &.is-active {
    color: $color-white;
  }
}
.program-type-bar-link__tid-583 {
  background: url('../images/program-map/icon-farm-based.svg') 10px 5px no-repeat;
  background-size: 35px 35px;
  &.is-active {
    background-color: $color-orange;
  }
}
.program-type-bar-link__tid-584 {
  background: url('../images/program-map/icon-garden-classrooms.svg') 10px 5px no-repeat;
  background-size: 35px 35px;
  &.is-active {
    background-color: $color-red;
  }
}
.program-type-bar-link__tid-585 {
  background: url('../images/program-map/icon-kitchen-classrooms.svg') 10px 5px no-repeat;
  background-size: 35px 35px;
  &.is-active {
    background-color: $color-green;
  }
}
.program-type-bar-link__tid-586 {
  background: url('../images/program-map/icon-school-cafeterias.svg') 10px 5px no-repeat;
  background-size: 35px 35px;
  &.is-active {
    background-color: $color-red-light;
  }
}
.program-type-bar-link__tid-587 {
  background: url('../images/program-map/icon-academic-classrooms.svg') 10px 5px no-repeat;
  background-size: 35px 35px;
  &.is-active {
    background-color: $color-blue;
  }
}
.program-type-bar-link__tid-588 {
  background: url('../images/program-map/icon-support-organizations.svg') 10px 5px no-repeat;
  background-size: 35px 35px;
  &.is-active {
    background-color: $color-purple;
  }
}
.program-map-toggle-sidebar {
  @include button;
  border-radius: 5px 0 0 5px;
  padding: 10px;
  text-indent: -999em;
  z-index: 998;
  width: 29px;
  height: 29px;
  background: $color-red url('../images/caret-white-left.png') 50% 50% no-repeat;
  &.is-toggled {
    background-image: url('../images/caret-white-right.png');
  }
}
.program-map-modal-close {
  @include button;
  font-weight: bold;
  display: block;
  float: right;
  margin-bottom: 10px;
  width: 15px;
  height: 15px;
  text-align: center;
  padding: 5px;
  position: relative;
  z-index: 2;
  &, &:link, &:hover, &:visited {
    color: $color-white;
    text-decoration: none;
  }
}
