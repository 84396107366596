.paragraphs-item-image-slider {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  .content {
    @include breakpoint($screen-m) {
      width: 76%;
      margin-left: 24%;
    }

    .field-item {
      background-color: $color-gray-light;
      text-align: center;
      line-height: 0;
      img {
        height: 100%;
        margin-left: auto;
        margin-right: auto;
      }

      .field-name-field-file-image-caption {
        background-color: $color-gray-lighter;
        .field-item {
          background-color: $color-gray-lighter;
          line-height: 1.7;
        }
      }
    }
  }
}
