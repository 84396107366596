.view-mode-full--user-profile {
  .pseudo-field-user-program-affiliation {
    @include dotted-border(single);
    padding-bottom: 30px;
    .esyp-profile-programs-more {
      font-size: 10px;
    }
  }
  .field-name-field-about-me {
    .field-label {
      @include field-label-large;
    }
  }
}
