.block-esyp-search-esyp-search-block {
  label {
    display: none;
  }
  #esyp-search-form {
    position: relative;
    width: 220px;

    @include breakpoint($screen-mobile-nav) {
      width: auto;
    }
  }
  .form-type-textfield {
    background: none;
    padding-top: 0;
    padding-bottom: 0;
    &:before {
      border: none;
    }
  }
  .form-text {
    display: block;
    width: 190px;
    padding: 7px 13px;
    border-radius: 18px;
    border: solid 1px $color-gray-light;
    color: $color-gray;
    font-size: 11px;

    @include breakpoint($screen-mobile-nav) {
      float: right;
      width: 175px;
      padding: 8px 15px;
      font-size: 12px;
    }

    &::placeholder {
      color: $color-gray-light;
      font-size: 11px;
      font-weight: 800;
      font-family: $font-muli;

      @include breakpoint($screen-mobile-nav) {
        font-size: 13px;
      }
    }
  }
  .form-submit {
    position: absolute;
    top: 5px;
    right: 13px;
    margin: 0;
    background-color: transparent;
    background-image: url('../images/search_glass.png');
    background-repeat: no-repeat;
    background-position: 95% 50%;
    background-size: 15px;
    padding-left: 10px;

    @include breakpoint($screen-mobile-nav) {
      top: 0;
      right: 13px;
    }
  }
}
