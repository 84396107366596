.view-mode-search-result {
  .content {
    padding-top: 20px;
  }

  .field-name-title-field {
    margin-top: 20px;
  }

  .type-banner {
    position: absolute;
    width: 100%;
    color: $color-white;
    top: 0;
    left: 0;
    text-align: center;
    font-size: 10px;
    line-height: 18px;
    font-weight: normal;
    text-transform: uppercase;
    &.search_result-page {
      background-color: $color-orange;
    }
    &.search_result-program {
      background-color: $color-green;
    }
    &.search_result-progression_page {
      background-color: $color-yellow;
    }
    &.search_result-resource_detail {
      background-color: $color-purple;
    }
  }
  .group-description {
    @include dotted-border('single');
  }
}
