.node-timeline {
  .node-title {
    display: none;
  }
  ol, ul {
    list-style: none;
  }
}

.frst-container.frst-carousel {
  margin-left: auto;
  margin-right: 0;
}

.frst-timeline-style-17 {
  a {
    text-decoration: none;
  }
  .timeline {
    margin: 0 0 0 auto;
  }
  .timeline .esyp-timeline-year {
    color: $color-red;
    font-size: 10px;
    font-weight: 800;
    &:after {
      height: 12px;
      width: 12px;
      bottom: -5px;
    }
    &.selected::after {
      height: 20px;
      width: 20px;
      bottom: -9px;
    }
  }
  .events-content {
    width: 90%;
    max-width: 800px;
    margin: 0 0 0 auto;
    li {
      padding: 0;
    }
  }
  .events-content p.esyp-timeline-description {
    font-size: 14px;
    line-height: 22px;
    color: $color-black;
  }
}
.esyp-timeline-title {
  padding-top: 10px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 800;
  text-transform: uppercase;
  color: $color-red;
}
.esyp-timeline-date {
  font-size: 14px;
  line-height: 22px;
  color: $color-gray-medium;
}
.frst-timeline-navigation a.next::before {
  left: -21px;
  top: 4px;
}
.frst-timeline-navigation a.prev::before {
  left: -24px;
  top: 4px;
}
.frst-timeline-navigation a.next::before,
.frst-timeline-navigation a.prev::before {
  font-size: 16px;
}
