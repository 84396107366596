.view-mode-teaser-node-type-blog-post {
  display: block;
  margin: 15px;
  background-color: $color-white;
  box-shadow: 0px 0px 10px 0px $color-black-20;
  .content {
    width: auto;
    padding: 0px;
  }
  .field-name-field-blog-image {
    display: none;
  }
  .field-pseudo-blog-teaser-card-image {
    display: block;
    width: 100%;
    padding-bottom: calc((100% / 3) * 2);
    background-color: $color-purple;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .field-pseudo-blog-post-date {
    position: relative;
    margin: 10px 15px 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: $color-gray-slate;
    font-weight: 600;
    text-align: left;
    span {
      padding-bottom: 0px;
      border: none;
    }
  }
  .field-name-field-post-type {
    margin: 10px 15px 0px;
    color: $color-gray-slate;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .field-name-title-field {
    padding-bottom: 10px;
    &:after {
      content: none;
    }
    h3 {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.4px;
      line-height: 26px;
    }
  }
  .field-name-field-blog-guest-author .field-item {
    padding-bottom: 20px;
    font-family: $font-muli;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 0.7px;
    line-height: 20px;
    text-transform: uppercase;
  }
  .field-pseudo-blog-post-featured-teaser-text {
    display: none;
  }
  .field-name-body {
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: $color-gray;
    font-family: $font-muli;
    font-size: 15px;
    letter-spacing: 0.5px;
    line-height: 26px;
    p {
      margin-bottom: 0px;
      &:first-child:first-letter {
        color: inherit;
        font-family: $font-muli;
        font-size: inherit;
        line-height: inherit;
        float: none;
        font-weight: inherit;
        padding: 0px;
      }
    }
  }
  .field-name-field-news-source {
    display: flex;
    padding-top: 10px;
    color: $color-gray-slate;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 20px;
    justify-content: center;
    text-transform: uppercase;
    .field-label {
      font-weight: 400;
    }
  }
}

.group-blog-card-content-outer-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.group-blog-card-content-inner-wrapper {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.field-pseudo-blog-post-type-icon {
  position: relative;
  display: flex;
  height: 50px;
  width: 50px;
  margin: -25px auto;
  background-color: $color-white;
  border-radius: 25px;
  justify-content: center;
  .esyp-blog-post-type-icon {
    margin-top: -10px;
  }
}
