.view-mode-map-teaser {
  cursor: pointer;
  padding-bottom: 10px;
  background: url('../images/dots.png') 0 100% repeat-x;
  margin-bottom: 18px;
  .content {
    display: flex;
  }
  h2 {
    font-family: $font-maitree;
    font-size: 18px;
    line-height: 22px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0;
  }
  .field-name-field-program-logo-photo {
    margin-left: 18px;
    margin-top: 10px;
    width: 118px;
    flex-shrink: 0;
    img {
      display: block;
    }
  }
  .field {
    font-family: $font-muli;
    font-size: 12px;
    line-height: 18px;
  }
  .field-name-field-program-type {
    .field-item {
      display: inline-block;
    }
  }
  .field-name-field-program-address {
    margin-top: 6px;
    margin-bottom: 6px;
    font-family: $font-muli;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
  }
}
