.paragraphs-item-hero-block-reference {
  margin-top: -15px;
  > .content {
    position: relative;
  }
  .field-name-field-hero-image {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &:after {
      content: none;
    }
    &:before {
      @include image-crimp(30px, -15px, transparent 50%, $color-cream 25%);
    }
    .field-items {
      height: 100%;
    }
    .field-item {
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  .field-name-field-block-reference,
  #block-esyp-webforms-esyp-webform-authentication {
    position: relative;
    width: 90%;
    margin: 0 auto;
    padding: 85px 0;
    min-height: 775px;
    @include breakpoint($screen-m) {
      width: 75%;
      padding: 90px 50px;
    }
    @include breakpoint($screen-l) {
      width: 50%;
      padding: 105px 50px;
    }
  }
}

.paragraphs-item-hero-block-reference #block-esyp-webforms-esyp-webform-authentication {
  min-height: 300px;
  .pledge-webform.esyp-webform-auth-custom-block {
    border: 10px solid $color-orange-dark;
    text-align: center;
    h3 {
      color: $color-gray-slate;
      font-size: 40px;
    }
    p {
      font-size: 18px;
      font-weight: 600;
    }
    .button {
      background-color: $color-orange-dark;
      padding: 15px 40px;
      font-size: 20px;
      font-weight: 700;
      &:hover {
        background-color: $color-purple;
      }
    }
    .user-sign-up-link {
      color: $color-black;
      margin-top: 15px;
      font-size: 16px;
    }
  }
}

.paragraphs-item-hero-block-reference .field-name-field-hero-image:before {
  content: " ";
  display: block;
  position: relative;
  width: 100%;
  bottom: -15px;
  height: 30px;
  background-repeat: repeat-x;
}

.paragraphs-item-hero-block-reference.border-beige .field-name-field-hero-image:before {
  background: linear-gradient(45deg, transparent 50%, $color-cream 25%) 0 50%,
    linear-gradient( -45deg, transparent 50%, $color-cream 25%) 0 50%;
  background-size: 30px 30px, 30px 30px;
}
.paragraphs-item-hero-block-reference.border-green .field-name-field-hero-image:before {
  background: linear-gradient(45deg, transparent 50%, $color-white 25%) 0 50%,
    linear-gradient( -45deg, transparent 50%, $color-white 25%) 0 50%;
  background-size: 30px 30px, 30px 30px;
}
