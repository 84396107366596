.paragraphs-item-image-bar {
  padding: 10px;
  margin-bottom: 20px;
  .field-name-field-image > .field-items {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include breakpoint($screen-m) {
      flex-direction: row;
    }
    > .field-item {
      height: 300px;
      width: 100%;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include breakpoint($screen-s) {
        height: 400px;
      }
      @include breakpoint($screen-m) {
        height: 500px;
      }
      .field-name-field-file-image-caption {
        position: absolute;
        bottom: -24px;
        left: 0;
      }
    }
  }
  &.image-bar-2 .field-name-field-image > .field-items {
    > .field-item {
      @include breakpoint($screen-m) {
        &.even {
          width: 65%;
        }
        &.odd {
          width: 33%;
        }
      }
    }
  }
  &.image-bar-3 .field-name-field-image > .field-items {
    > .field-item {
      @include breakpoint($screen-m) {
        width: 32%;
      }
    }
  }
}
