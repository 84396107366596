.border-green .pledge-webform {
  border: 10px solid $color-green;
}

.pledge-webform {
  background-color: $color-white;
  border: 10px solid $color-cream;
  padding: 20px 40px;
  @include breakpoint($screen-m) {
    padding: 40px;
  }
  #content {
    padding: 5% 10%;
  }
  #page-title,
  .block-title {
    display: none;
  }
  input[type="text"],
  input[type="email"],
  textarea.form-textarea,
  .chosen-single,
  .form-select {
    box-sizing: border-box;
    border: none;
    height: 50px;
    width: 100%;
    padding: 0 20px;
    font-size: 11px;
    font-weight: 700;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  input[type="submit"] {
    background-color: $color-orange-dark;
    font-size: 18px;
    font-weight: 600;
    padding: 15px 40px;
    &:hover {
      background-color: $color-purple;
    }
  }
  input[type="checkbox"] {
    margin-right: 10px;
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  .flex-50 .fieldset-wrapper {
    justify-content: space-between;
    & > .form-item {
      width: 49%;
    }
  }
  .flex-60-40 .fieldset-wrapper {
    justify-content: space-between;
    & > .flex-60 {
      width: 59%;
    }
    & > .flex-40 {
      width:39%;
    }
  }

  .show-label.form-item label,
  .show-label .form-item label,
  .show-label.custom-markup {
    display: inline;
    color: $color-black;
    text-transform: none;
    font-size: 14px;
    padding-top: 5px;
  }
  .form-item label {
    display: none;
  }
  .webform-component-fieldset.flex-box .fieldset-wrapper {
    display: flex;
  }
  .confirmation-message {
    text-align: center;
    font-family: $font-maitree;
    font-size: 20px;
  }
  .links {
    display: flex;
    .ajax-processed {
      margin: 0 auto;
    }
  }
  div > .form-actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .form-actions .webform-previous[type="submit"] {
    background-color: $color-gray-medium;
  }
}

.pledge-webform .webform-progressbar {
  width: 35%;
  .webform-progressbar-outer {
    border-top-color: $color-orange-dark;
    height: 10px;
  }
  .webform-progressbar-page.current {
    &:before {
      background-color: $color-orange-dark;
      top: -12px;
      width: 20px;
      height: 20px;
    }
  }
  .webform-progressbar-page:before {
    top: -8px;
    border: 2px solid $color-orange-dark;
    background-color: $color-white;
    border-radius: 15px;
  }
}
