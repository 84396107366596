.view-mode-sidebar-detail--user-profile {
  .pseudo-field-pseudo-profile-photo,
  .pseudo-field-pseudo-civi-fuzzy-address {
    @include dotted-border('single');
    padding-bottom: 15px;
  }
  .pseudo-field-pseudo-civi-fuzzy-address,
  .pseudo-field-civi-contact-info {
    padding-top: 15px;
  }
  .pseudo-field-fuzzy-address__country {
    display: block;
  }
  .pseudo-field-civi-contact-info-email {
    margin-left: auto;
  }
}
