.node-blog-post .addtoany_list {
  display: block;
  text-align: center;
  @include breakpoint((max-width: $screen-m)) {
    position: relative !important;
    top: 0 !important;
    margin-top: 30px;
  }
  @include breakpoint($screen-m) {
    float: left;
    margin: 38px 0px 0px -110px;
  }
  .share-title {
    color: $color-purple;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    letter-spacing: 1px;
    span {
      border-bottom: solid 1px $color-purple;
      padding-bottom: 2px;
    }
  }
  & > a {
    @include breakpoint($screen-m) {
      display: block;
      margin-bottom: 3px;
    }
  }
}
