.field-name-field-hero-image,
.field-name-field-hero-image-mobile {
  &:after {
    @include image-crimp(30px, 35px, $color-gray-lighter 30%, transparent 25%);
  }
}
.page-background-color-cream .field-name-field-hero-image,
.page-background-color-cream .field-name-field-hero-image-mobile {
  &:after {
    @include image-crimp(30px, 35px, $color-cream 30%, transparent 25%);
  }
}

.field-name-field-hero-image,
.field-name-field-hero-image-mobile,
.page-background-color-cream .field-name-field-hero-image {
  img {
    width: 100%;
  }
}

.paragraphs-item-hero-image.has-mobile-alt {
  .field-name-field-hero-image {
    display: none;
    @include breakpoint($screen-m) {
      display: block;
    }
  }
  .field-name-field-hero-image-mobile {
    display: block;
    @include breakpoint($screen-m) {
      display: none;
    }
  }
}
