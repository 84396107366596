.webform-client-form {
  legend {
    @extend %h3;
  }
  .webform-component-grid {
    th,
    td {
      padding: 8px;
    }
  }
}
.contact-us__intro__addresses {
  @include breakpoint($screen-m) {
    @include clearfix;
    max-width: 500px;
    & > p {
      float: left;
    }
    p + p {
      float: right;
    }
  }
}
.webform-progressbar {
  width: 100%;
}
.webform-progressbar-outer {
  display: flex;
  justify-content: space-between;
  height: auto;
  border: none;
  border-top: 3px solid $color-red;
}
.webform-progressbar-page {
  display: block;
  position: relative;
  width: auto;
  height: auto;
  margin: 0;
  border: none;
  background-color: transparent;
  &:before {
    position: absolute;
    top: -7px;
    left: 50%;
    margin-left: -5px;
    width: 10px;
    height: 10px;
    content: ' ';
    border: 1px solid $color-red;
    background-color: white;
    border-radius: 10px;
  }
  &.current,
  &.completed {
    background-color: transparent;
    &:before {
      background-color: $color-red;
    }
  }
  &:first-child:before {
    left: 0;
    margin-left: 0;
  }
  &:last-child:before {
    left: auto;
    right: 0;
    margin-left: 0;
  }
  .webform-progressbar-page-label {
    margin: 0;
    display: block;
    position: relative;
    width: auto;
    height: auto;
    line-height: 1.25;
  }
}
