.region-sidebar-first #block-views-exp-search-page,
.region-sidebar-first #block-views-exp-search-page--2,
.region-sidebar-first #block-views-exp-resource-search-page {
  .form-select,
  .option {
    font-family: $font-muli;
    font-size: 10px;
    line-height: 24px;
    text-transform: uppercase;
  }
  .views-exposed-widgets {
    display: flex;
    flex-direction: column;
    > #-wrapper {
      display: none;
    }
  }
  .bef-select-as-checkboxes-fieldset {
    border: none;
    .fieldset-title {
      @extend h6;
      text-decoration: none;
    }
    > legend {
      float: left;
      .fieldset-legend {
        background: none;
        padding-left: 0;
      }
    }
    &.collapse-processed {
      background-image: url('../images/carrot.png');
      background-position: right 5px;
      background-repeat: no-repeat;
      background-size: 8px;
      height: auto;
    }
    &.collapse-processed.collapsed {
      background-image: url('../images/left_carrot.png');
    }
    .bef-checkboxes .form-type-bef-checkbox {
      input[type=checkbox] {
      display: none;
        &:checked {
          background-color: $color-red;
        }
      }
      label {
        padding-left: 20px;
        position: relative;
        &:before {
          content: '';
          border-radius: 50px;
          width: 8px;
          height: 8px;
          left: 3px;
          top: 1px;
          position: absolute;
          border: 1px solid $color-red;
          cursor: pointer;
        }
      }
      input:checked + label:before {
        background-color: $color-red;
      }
    }
  }
  .views-widget-filter-search_api_views_fulltext {
    padding-bottom: 20px;
    .description {
      display: none;
    }
    .form-type-textfield {
      display: flex;
      background: none;
      &:before {
        content: none;
      }
      input.form-text {
        box-sizing: border-box;
        width: 100%;
        border: 1px solid $color-gray-light;
        border-radius: 50px;
        font-family: $font-muli;
        font-size: 12px;
        line-height: 28px;
        background-color: $color-white;
        background-image: url('../images/search_glass.png');
        background-repeat: no-repeat;
        background-position: 95% 50%;
        background-size: 15px;
        padding-left: 10px;
      }
    }
  }
  .views-exposed-widget.views-submit-button {
    fieldset.form-wrapper {
      border: none;
      padding: 0;
      margin: 0;
    }
    .fieldset-wrapper {
      .form-item.form-type-select {
        background-position: top;
        background-image: url('../images/single-red-dash.png');
        padding: 15px 0;
        &:after {
          content: none;
        }
      }
    }
    legend {
      width: 100%;
      .fieldset-legend {
        width: 100%;
      }
      a {
        width: 100%;
        display: block;
      }
      .summary {
        display: none;
      }
    }
    #edit-group1,
    #edit-group2,
    #edit-group3 {
      > legend {
        font-family: $font-maitree;
        color: $color-red;
        font-size: 20px;
        line-height: 22px;
        font-weight: 600;
        padding: 20px 0;
        border-top: 4px solid $color-red;
        background-repeat: no-repeat;
        .fieldset-legend {
          padding-right: 40px;
          box-sizing: border-box;
        }
      }
    }
  }
  .views-reset-button {
    display: flex;
    justify-content: center;
    input {
      background-color: $color-white;
      border: 1px solid $color-red;
      color: $color-red;
      font-weight: bold;
    }
  }
}

.region-sidebar-first #block-views-exp-search-page,
.region-sidebar-first #block-views-exp-search-page--2 {
  .views-widget-filter-search_api_views_fulltext {
    > label {
      display: block;
      font-family: $font-maitree;
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 10px;
    }
  }
  .form-item-field-program-type .bef-checkboxes {
    .form-item {
      padding: 3px 0;
      background-size: 15px 15px;
      label {
        padding: 6px 0 7px 55px;
        background-position: 22px 0;
        background-repeat: no-repeat;
        &::before {
          top: 8px;
        }
      }
    }
  }
  .form-item-edit-field-program-type-583 label {
    background-image: url('../images/program-map/icon-farm-based.svg');
  }
  .form-item-edit-field-program-type-584 label {
    background-image: url('../images/program-map/icon-garden-classrooms.svg');
  }
  .form-item-edit-field-program-type-585 label {
    background-image: url('../images/program-map/icon-kitchen-classrooms.svg');
  }
  .form-item-edit-field-program-type-586 label {
    background-image: url('../images/program-map/icon-school-cafeterias.svg');
  }
  .form-item-edit-field-program-type-587 label {
    background-image: url('../images/program-map/icon-academic-classrooms.svg');
  }
  .form-item-edit-field-program-type-588 label {
    background-image: url('../images/program-map/icon-support-organizations.svg');
  }
}

.region-sidebar-first #block-views-exp-resource-search-page .views-widget-filter-search_api_views_fulltext label {
  display: none;
}
