.form-item,
.mc-field-group {
  label {
    @include field-label-red;
    font-size: 11px;
  }
  .option {
    color: $color-gray;
    font-family: $font-muli;
    font-size: 13px;
    line-height: $base-line-height;
    text-transform: none;
    font-weight: normal;
  }
}
.form-text,
.form-select,
.form-textarea,
.mc-field-group input[type="email"],
.mc-field-group input[type="zip"],
.mc-field-group input[type="text"] {
  padding: 5px;
  font-family: $font-muli;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1px;
  border: solid 1px $color-gray-light;
  background-color: $color-gray-lighter;
  max-width: 100%;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: $color-gray-slate;
    opacity: 1;
  }
}
.form-type-select {
  @include form-select;
}
.form-textarea-wrapper {
  max-width: 600px;
}
input[type="submit"] {
  @include button;
  cursor: pointer;
  margin-right: 15px;
}