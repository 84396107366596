.view-mode-full-node-type-program {
  .field-name-field-program-affiliation {
    @include dotted-border(single);
    > .field-items {
      display: flex;
      padding: 15px;
      > .field-item {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
  .group-program-basics {
    padding: 20px 0;
    @include dotted-border(single);
    .field {
      line-height: 22px;
    }
    .field-label {
      @include field-label-red;
      padding-top: 1px;
      line-height: 21px;
    }
  }
  .field-name-body,
  .field-name-field-program-members {
    @include field-with-label-heading;
  }
  .field-name-field-program-members {
    > .field-items {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: visible;
      margin-left: -10px;
      min-height: 290px;
    }
    > .field-items > .field-item {
      flex-shrink: 0;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
