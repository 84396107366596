.view-mode-full-node-type-resource-detail,
.view-mode-print-node-type-resource-detail {
  .field-collection-item-field-procedure-steps,
  .group-basic-info,
  .contributor-tags-row {
    @include dotted-border(single);
  }

  .field-collection-item-field-procedure-steps {
    padding-bottom: 20px;
  }

  .field-type-text-long {
    #{$block-level-elements-basic} {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .field-name-field-tags,
  .group-resource-header .field,
  .field-name-field-procedure-steps,
  .field-name-field-procedure-steps .field-item:last-child,
  .field-name-field-academic-standards-descrip {
    background: none;
    padding-bottom: 0;
  }

  .field-name-field-academic-standards-descrip {
    #{$block-level-elements-basic} {
      &:last-child {
        margin-bottom: 30px;
      }
    }
  }

  .group-resource-header {
    padding-bottom: 40px;
    > div {
      margin-bottom: 15px;
    }
    @include breakpoint($screen-m) {
      display: flex;
      > div {
        margin-bottom: 0;
        padding-left: 15px;
        &:first-child {
          padding-left: 0;
        }
      }
    }
    .field-label {
      @include field-label-red;
    }
  }

  .field-name-field-progression {
    display: flex;
    align-items: center;
    color: $color-gray;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
  }

  .pseudo-standards-aligned {
    display: flex;
    flex-direction: row;
    align-items: center;
    .field-label {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: normal;
      color: $color-black;
      padding-right: 5px;
    }
  }

  .group-basic-info {
    padding: 15px 0;
    @include breakpoint($screen-m) {
      display: flex;
      > .field {
        margin-right: 10px;
        width: 25%;
      }
    }
    .field-label {
      @include field-label-red;
      margin-top: 0;
      margin-bottom: 0;
    }
    .field-item {
      font-size: 26px;
      line-height: 32px;
      color: $color-red;
      font-weight: 200;
    }
  }

  .contributor-tags-row {
    .field-label {
      margin-bottom: 0;
    }
  }

  .pseudo-field-resource-metadata {
    ul {
      list-style-type: none;
      padding-left: 0;
    }
    &.cols-2 ul {
      column-count: 2;
    }
  }

  .field-name-field-lesson-summary {
    font-size: 16px;
  }


  .field-name-field-procedure-steps {
    >.field-items {
      >.field-item {
        padding-top: 10px;
        @extend %horizontal-polka-dots;
        &:first-child {
          background: none;
        }
      }
    }
    .step-number {
      border: 1px solid $color-red;
      border-radius: 75px;
      padding: 10px;
      height: 10px;
      width: 10px;
      text-align: center;
      line-height: 10px;
      color: $color-red;
      position: absolute;
      left: -50px;
      top: -5px;
    }
    .field-collection-item-field-procedure-steps {
      padding: 5px 0 30px 50px;
      .field-name-field-step-duration {
        font-size: 10px;
        line-height: 22px;
        padding-bottom: 5px;
      }
      h5 {
        margin-top: 12px;
      }
      .field-name-field-description {
        .field-item {
          position: relative;
        }
      }
    }
  }

  .group-edible-schoolyard-standards,
  .group-academic-standards {
    @include dotted-border(single);
    padding-bottom: 30px;
    position: relative;
    .field-group-format-wrapper {
      width: 85%;
    }
    > h3 {
      text-decoration: none;
      margin-bottom: 10px;
      display: block;
      &:before {
        background-image: url('../images/checked.png');
        background-size: 63%;
        background-repeat: no-repeat;
        content: '';
        display: inline-block;
        height: 21px;
        padding-right: 20px;
        width: 15px;
      }
    }
  }

 .progression-count {
    display: flex;
    font-size: 20px;
    color: $color-red;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-red;
    border-radius: 25px;
    line-height: 1.7;
    font-weight: 300;
    width: 15%;
    min-width: 125px;
    padding-left: 5px;
    padding-right: 5px;
    .field-label {
      letter-spacing: 5.5px;
      @include field-label-red;
      padding-right: 5px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .field-name-field-additional-text {
    background-image: none;
    padding-bottom: none;
  }

  .field-collection-item-field-additional-text {
    @include dotted-border(single);
    padding-bottom: 30px;
  }

  .field-name-field-heading {
    @include field-label-large;
  }

  .resource-footer {
    padding-top: 20px;
    padding-bottom: 0 !important;
    color: $color-gray;
    text-transform: uppercase;
    background-image: none;
    @include breakpoint($screen-m) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .progression {
      display: flex;
      flex-direction: column;
      align-items: center;
      .progression-title {
        padding-top: 15px;
        color: $color-gray;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 1px;
        text-align: center;
      }
    }
    .previous-lesson, .next-lesson {
      @extend %prev-next-pager-wrapper;
    }
    .previous-lesson {
      @extend %prev-next-pager-prev-link;
    }
    .next-lesson {
      @extend %prev-next-pager-next-link;
    }
  }
}
