#block-views-supportee-programs-block {
  padding-top: 20px;
  padding-bottom: 20px;
  .block-title {
    @include field-label-large;
  }
}
.block-views-video-cards-block,
.block-views-video-cards-block-esyb,
.block-views-video-cards-block-ee101 {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
