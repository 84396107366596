.page-search {
  .l-content-wrapper > .column {
    padding-top: 60px;
  }
  .view-search .view-header,
  .view-empty {
    color: $color-gray-medium;
    font-size: 22px;
  }
  .view-search .view-header {
    position: absolute;
    top: -53px;
    left: 7px;
  }
}

.page-search,
.page-resource-search {
  .view-search,
  .view-resource-search {
    @include view-masonry-grid;
    padding-bottom: 60px;
    margin-bottom: 10px;
  }
  #page-title {
    display: none;
  }
}
