body {
  color: $color-gray;
  font-family: $font-muli;
  font-size: 15px;
  line-height: $base-line-height;
}

a,
%link-default {
  &, &:link, &:visited, &.active {
    color: $color-purple;
    text-decoration: none;
    transition: all .2s ease-in-out 0s;
  }
  &:hover {
    color: $color-black;
    text-decoration: underline;
  }
}

%horizontal-polka-dots {
  background-image: url(../images/dash-single.svg);
  background-position: bottom;
  background-size: 7px 2.5px;
  background-repeat: repeat-x;
}
.background-color-red {
  background-color: $color-red;
}
.background-color-light_red {
  background-color: $color-red-light;
}
.background-color-blue {
  background-color: $color-blue;
}
.background-color-green {
  background-color: $color-green;
}
.background-color-olive {
  background-color: $color-olive;
}
.background-color-purple {
  background-color: $color-purple;
}
.background-color-orange {
  background-color: $color-orange;
}
h1, h2, h3, h4 {
  line-height: $heading-line-height;
}
.media-float-left {
  @include breakpoint($screen-m) {
    float: left;
    padding-right: 35px;
    padding-left: 15px
  }
}
.media-float-right {
  @include breakpoint($screen-m) {
    float: right;
    padding-left: 35px;
    padding-right: 15px;
  }
}
img {
  max-width: 100%;
  height: auto;
}
.element-invisible {
  @include screen-reader-only;
}
