.view-mode-full-node-type-resource-detail,
.view-mode-full-node-type-progression-page,
.view-mode-print-node-type-resource-detail {
  .content {
    display: flex;
    flex-direction: column;
    .field-label {
      @include field-label-large;
    }
  }
  > .content {
    padding-top: 65px;
  }
  .field {
    @include dotted-border(single);
    padding-bottom: 30px;
  }
  .group-basic-info > .field,
  .entity-field-collection-item .field {
    background: none;
    padding-bottom: 0;
  }
  .field-name-title-field {
    @include page-title-with-border;
    padding-bottom: 20px;
    padding-left: 42px;
    @include dotted-border(double, url('../images/lesson.png'), 0px 22px,  35px auto, no-repeat);

  }
  .contributor-tags-row,
  .group-basic-info {
    padding: 15px 0;
    @include breakpoint($screen-m) {
      display: flex;
    }
    .field-label {
      @include field-label-red;
      margin-top: 0;
    }
    .contributor {
      width: 50%;
    }
    p {
      margin: 0;
    }
  }
  .field-type-text-long {
    .field-items {
      width: 85%;
    }
  }
}
.view-mode-full-node-type-progression-page {
  .field-collection-container {
    border-bottom: none;
  }
  .field-name-field-related-resources {
    background-position: top left;
    padding-top: 25px;
    margin-top: 20px;
  }
}
