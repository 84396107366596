.block-simple-instagram-feed {
  padding-left: 20px;
  padding-right: 20px;
  &.is-loading {
    max-height: 300px;
    overflow: hidden;
    img {
      visibility: hidden;
    }
  }
  .block-title {
    color: $color-red;
  }
  .slick-slider {
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 40px;
  }
  .slick-prev,
  .slick-next {
    position: absolute;
    top: calc(50% - 15px);
    -webkit-appearance: none;
    text-indent: -999em;
    width: 30px;
    height: 30px;
    border: none;
    z-index: 999;
    &.slick-disabled {
      opacity: .2;
    }
  }
  .slick-prev {
    left: 0;
    background: url('../images/slider-left-black.svg') 50% 50% no-repeat;
    background-size: 30px 30px;
  }
  .slick-next {
    left: auto;
    right: 0;
    background: url('../images/slider-right-black.svg') 50% 50% no-repeat;
    background-size: 30px 30px;
  }
}
