.view-mode-sidebar-detail {
  .field-name-field-image {
    display: none;
    @include breakpoint($screen-m) {
      display: inline;
    }
  }
}
.region-sidebar-first .block-print-ui-print-links .content {
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  span {
    width: 25%;
  }
  a.print-pdf {
    text-decoration: none;
    text-transform: uppercase;
    color: $color-red;
    display: block;
    background: url('../images/download.png');
    background-size: 45px;
    background-repeat: no-repeat;
    background-position: top center;
    text-align: center;
    padding-top: 50px;
    font-size: 8px;
    letter-spacing: 2px;
    font-weight: 800;
    &:hover {
      color: $color-purple;
      background-image: url('../images/download_hover.png');
    }
  }
  a.print-page {
    text-decoration: none;
    text-transform: uppercase;
    color: $color-red;
    display: block;
    background: url('../images/print.png');
    background-size: 45px;
    background-repeat: no-repeat;
    background-position: top center;
    text-align: center;
    padding-top: 50px;
    font-size: 8px;
    letter-spacing: 2px;
    font-weight: 800;
    &:hover {
      color: $color-purple;
      background-image: url('../images/print_hover.png');
    }
  }
}
