/*
Most styling for print mode resource detail are pulled from _view-mode-full--resource.scss and _view-mode-full.scss
mPDF has restrictions and limitations on what styles can be used for pdf generation. See here for more information: https://mpdf.github.io/about-mpdf/limitations.html
*/
.view-mode-print-node-type-resource-detail {
  > .content {
    padding-top: 0;
  }
  .progression-count {
    border: none;
  }
  .field-pseudo-field.resource-footer {
    display: none;
  }
  .field-name-field-procedure-steps .field-collection-item-field-procedure-steps {
    padding: 5px 0 30px 0;
    .field-name-field-description {
      .field-item {
        position: static;
      }
      .step-number {
        position: static;
        width: 2%;
      }
    }
  }
  .pseudo-standards-aligned .field-item {
    background-image: none;
    width: auto;
    height: auto;
    .field-value {
      position: static;
      font-family: $font-muli;
      font-size: 10px;
      line-height: 22px;
      padding: 0;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 1px;
    }
  }
}
