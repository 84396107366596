.field-name-field-cornell-note {
  width: 100%;
  box-sizing: border-box;
  @include breakpoint($screen-m) {
    padding-left: 10px;
    position: absolute;
    width: 30%;
    left: 0;
    top: 0;
  }
  @include breakpoint($screen-l) {
    width: 40%;
    padding-right: 40px;
  }
  &.cornell-placement-top {
    margin-top: 1em;
  }
  &.cornell-placement-center {
    @include breakpoint($screen-m) {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.cornell-placement-bottom {
    @include breakpoint($screen-m) {
      top: auto;
      bottom: 0;
    }
  }
  .field-label {
    @include field-label-red;
    line-height: 28px;
  }
  .paragraphs-item-cornell-note-quote {
    width: 60%;
  }
  .paragraphs-item-cornell-note-quick-note {
    width: 80%;
  }
}
.paragraphs-item-cornell-note-quick-note,
.paragraphs-item-cornell-note-quote {
  margin-left: 0;
  .field-name-field-body {
    p {
      margin-top: 0;
      @include paragraph-tag-reset;
    }
  }
}
.paragraphs-item-cornell-note-quick-note {
  padding-left: 38px;
  background: url('../images/pencil.svg') 0px 0px no-repeat;
  background-size: 28px 28px;
}
.paragraphs-item-cornell-note-quote {
  &:before {
    content: '\201C';
    display: block;
    font-family: $font-muli;
    font-size: 53px;
    line-height: 53px;
    font-weight: 800;
    color: $color-red;
  }
  .field-name-field-body {
    p {
      margin-bottom: 0;
    }
  }
  .field-name-field-attribution-name {
    @include field-label-red;
    font-size: 12px;
    &:before {
      content: '\2013';
    }
  }
  .field-name-field-attribution-source {
    font-size: 12px;
    line-height: 16px;
    font-family: $font-muli;
    color: $color-gray;
    font-weight: 300;
  }
}
.paragraphs-item-cornell-note-photo {
  @include breakpoint($screen-m) {
    padding-right: 20px;
  }
  .field-name-field-file-image-caption {
    color: $color-gray;
  }
}
