.paragraphs-item-call-to-action {
  color: $color-white;
  padding: 15px 10px;
  .content {
    a {
      color: $color-white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-decoration: none;
      @include breakpoint($screen-l) {
        flex-direction: row;
      }
      .field-name-field-action {
        border-bottom: 1px solid white;
        font-size: 10px;
        font-weight: 600;
        padding: 4px 2px;
        text-transform: uppercase;
      }
      .field-name-field-title {
        font-family: $font-maitree;
        font-size: 28px;
        padding: 10px 20px 10px 10px;
        text-align: center;
        @include breakpoint($screen-l) {
          padding: 0 20px 0 10px;
        }
      }
    }
  }
}
