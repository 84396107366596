.view-mode-teaser-node-type-video-card {
  background-color: $color-white;
  position: relative;
  padding: 15px;

  .field-name-field-video-date {
    font-size: 13px;
    text-transform: uppercase;
    font-family: $font-muli;
    margin-bottom: 15px;
  }
  .field-name-field-speakers {
    @include field-label-large;
    margin-top: 0;
    margin-bottom: 0;
  }
  .field-name-field-readings {
    .field-label {
      @include field-label-red;
      font-size: 13px;
      text-align: center;
      color: $color-black;
    }
  }
  .field-name-title-field {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 15px;
  }
}
