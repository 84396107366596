// Breakpoints
$screen-s: 479px;
$screen-m: 767px;
$screen-l: 991px;
$screen-xl: 1199px;
$screen-xxl: 1419px;
$screen-mobile-nav: $screen-xl;

// Typography
$font-arial: Arial, Helvetica, sans-serif;
$font-muli: Muli, Arial, Helvetica, sans-serif;
$font-maitree: Maitree, Arial, Helvetica, sans-serif;
$font-oswald: Oswald, Arial, Helvetica, sans-serif;
$font-taviraj: Taviraj, Arial, Helvetica, sans-serif;

// Line Height
$base-line-height: 1.7;
$heading-line-height: 1.25;

// Colors
$color-black: #000000;
$color-black-24: rgba(0, 0, 0, .24);
$color-black-20: rgba(0, 0, 0, .20);
$color-white: #ffffff;
$color-gray: #323232;
$color-gray-light: #bebebe;
$color-gray-lighter: #f2f2ef;
$color-gray-medium: #999999;
$color-gray-slate: #757575;
$color-red: #f05343;
$color-red-light: #f28166;
$color-blue: #0f93d1;
$color-green: #898e36;
$color-olive: #b6a736;
$color-purple: #962061;
$color-orange: #f79620;
$color-orange-dark: #f26b2c;
$color-green-light: #d6e03d;
$color-green-dark: #6f8c28;

// Pledge Colors
$color-yellow: #f89720;
$color-orange-medium: #f16b2b;
$color-cream: #fcecb9;
$color-green-darkest: #142a00;

// Misc
$header-height: 122px;
$cta-height: 200px;

$block-level-elements-basic: p, table, blockquote, ol, ul, pre, fieldset, hr;
