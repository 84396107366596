.page-resource-search .view-empty {
  color: $color-gray-medium;
  font-size: 22px;
  @include breakpoint($screen-m) {
    padding-top: 10%;
  }
}

#block-views-exp-resource-search-page {
  .views-exposed-widget.views-submit-button {
    #edit-group1 > legend {
      background-image: url('../images/lesson.png');
      background-size: 40px;
      background-position: 100% 50%;

    }
    #edit-group2 > legend {
      background-image: url('../images/shovel.png');
      background-size: 30px;
      background-position: 98% 50%;

    }
    #edit-group3 > legend {
      background-image: url('../images/raddish.png');
      background-size: 25px;
      background-position: 98% 50%;
    }
  }
}
/*
 * This is a fix for the iphone "search" button. If the button is display: none;
 * clicking the "Search" button on the iphone keyboard will use the first visible
 * button, which is clear filters. Setting the form submit to display: block and
 * pulling it off the screen using absolute positioning fixes the issue.
 */
html.js #block-views-exp-resource-search-page {
  .views-submit-button .form-submit {
    display: block;
    @include screen-reader-only;
  }
}
