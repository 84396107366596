%prev-next-pager-wrapper {
  color: $color-gray-light;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 800;
  padding-bottom: 10px;
  max-width: 120px;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint($screen-m) {
    padding-bottom: 25px;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
  a {
    font-size: 10px;
    letter-spacing: 1px;
    text-decoration: none;
    font-weight: 800;
    display: inline-block;
  }
}
%prev-next-pager-link {
  display: block;
  padding-top: 10px;
  text-align: center;
  background-size: 55px auto;
  background-repeat: no-repeat;
  background-position: top center;
}
%prev-next-pager-prev-link {
  span, a {
    @extend %prev-next-pager-link;
    @include breakpoint($screen-m) {
      background-position: left;
      padding-top: 0;
      padding-left: 60px;
      text-align: left;
    }
  }
  span {
    background-image: url('../images/left_arrow_gray.svg');
  }
  a {
    background-image: url('../images/left_arrow_red.svg');
    &:hover {
      background-image: url('../images/left_arrow_purple.svg');
    }
  }
}

%prev-next-pager-next-link {
  margin-top: 15px;
  @include breakpoint($screen-m) {
    margin-top: 0;
  }
  span, a {
    @extend %prev-next-pager-link;
    @include breakpoint($screen-m) {
      background-position: right;
      padding-top: 0;
      padding-right: 60px;
    }
  }
  span {
    background-image: url('../images/right_arrow_gray.svg');
  }
  a {
    background-image: url('../images/right_arrow_red.svg');
    &:hover {
      background-image: url('../images/right_arrow_purple.svg');
    }
  }
}
