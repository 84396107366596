.paragraphs-item-two-column-images {
  padding: 0 15px 15px 15px;
  > .content {
    display: flex;
    flex-direction: column;
    @include breakpoint($screen-m) {
      flex-direction: row;
    }
  }
  .field-name-field-right-image {
    padding-left: 10px;
    @include breakpoint($screen-m) {
      padding-left: 20px;
    }
  }
  .field-name-field-left-image-mobile,
  .field-name-field-right-image-mobile {
    display: block;
    padding-bottom: 10px;
    @include breakpoint($screen-m) {
      display: none;
    }
  }
  .field-name-field-left-image,
  .field-name-field-right-image {
    display: none;
    @include breakpoint($screen-m) {
      display: block;
    }
  }
}
