#main-wrapper {
  @include breakpoint($screen-mobile-nav) {
    margin-top: $header-height;
  }
}

#page {
  background-color: $color-gray-lighter;
}

.page-node-type-webform,
.node-type-academy-application,
.page-user,
.page-email,
.page-civicrm-event,
.page-civicrm-contribute {
  @include page-container-crimp;
  #page-title {
    @include page-title-with-top-bottom-border;
  }
}

.crm-container a {
  @extend %link-default;
}

.page-civicrm-event-confirm {
  .l-main-wrapper {
    margin-bottom: 20px;
  }
  #crm-main-content-wrapper {
    margin-bottom: 80px;
  }
}
