.field-formatter-esyp-term-icons {
  display: inline-block;
  text-indent: -999em;
  width: 20px;
  height: 20px;
  padding-right: 5px;
}
.field-formatter-esyp-term-icons--tid-583 {
  background: url('../images/program-map/icon-farm-based.svg') 0px 0px no-repeat;
  background-size: 20px 20px;
}
.field-formatter-esyp-term-icons--tid-584 {
  background: url('../images/program-map/icon-garden-classrooms.svg') 0px 0px no-repeat;
  background-size: 20px 20px;
}
.field-formatter-esyp-term-icons--tid-585 {
  background: url('../images/program-map/icon-kitchen-classrooms.svg') 0px 0px no-repeat;
  background-size: 20px 20px;
}
.field-formatter-esyp-term-icons--tid-586 {
  background: url('../images/program-map/icon-school-cafeterias.svg') 0px 0px no-repeat;
  background-size: 20px 20px;
}
.field-formatter-esyp-term-icons--tid-587 {
  background: url('../images/program-map/icon-academic-classrooms.svg') 0px 0px no-repeat;
  background-size: 20px 20px;
}
.field-formatter-esyp-term-icons--tid-588 {
  background: url('../images/program-map/icon-support-organizations.svg') 0px 0px no-repeat;
  background-size: 20px 20px;
}
.field-formatter-esyp-term-icons--tid-589 {
  display: none;
}
.field-formatter-esyp-link-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-indent: -999em;
}
.field-formatter-esyp-link-icon--facebook {
  background: url('../images/facebook.png') 50% 0 no-repeat;
}
.field-formatter-esyp-link-icon--twitter {
  background: url('../images/twitter.png') 50% 0 no-repeat;
}
.field-name-field-accordion-item {
  position: relative;
  z-index: 10;
}
.field-collection-item-field-accordion-item {
  @include dotted-border(single);
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 16px;
    left: -30px;
    width: 10px;
    height: 11px;
    background-image: url('../images/black_arrow.png');
    background-repeat: no-repeat;
    background-size: 7px;
    transform: rotate(90deg);
  }
  &.is-collapsed::before {
    transform: none;
  }
}
.field-collection-item-field-accordion-item:first-child {
  background-image: url('../images/dash-single.svg'), url('../images/dash-single.svg');
  background-position: left top, left bottom;
  background-size: 6px 2px, 6px 2px;
  background-repeat: repeat-x, repeat-x;
}
.field-name-field-accordion-heading {
  color: $color-black;
  font-family: $font-muli;
  font-size: 15px;
  letter-spacing: 1px;
  padding: 10px 0;
  cursor: pointer;
}
.field-name-field-accordion-body p:first-child {
  margin-top: 0;
}
.field-name-field-related-resources {
  .field-collection-view {
    margin-left: 0;
  }
}
.field-name-field-resource-description {
  .field-collection-view {
    @include checkboxes;
    border-bottom: 0;
    .content {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
.field-name-field-fc-resource-type {
  margin-left: 4px;
}
.pseudo-standards-aligned {
  margin-left: auto;
  .field-item {
    background-image: url('../images/checked.png');
    background-size: 16px;
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
    margin-left: auto;
    margin-right: auto;
  }
  .field-value {
    @include screen-reader-only;
  }
}
.pseudo-field-civi-contact-info {
  display: flex;
  align-items: center;
}
.pseudo-field-civi-contact-info-social-link {
  display: block;
  width: 23px;
  height: 23px;
  margin-right: 10px;
  .esyp-profile-social-link-text {
    @include screen-reader-only;
  }
}
.pseudo-field-civi-contact-info-social-link__facebook {
  width: 11px;
  background: url('../images/facebook.png') 0 0 no-repeat;
}
.pseudo-field-civi-contact-info-social-link__twitter {
  width: 28px;
  background: url('../images/twitter.png') 0 0 no-repeat;
}
.pseudo-field-civi-contact-info-social-link__linkedin {
  width: 23px;
  background: url('../images/linkedin.png') 0 0 no-repeat;
}
.pseudo-field-civi-contact-info-email {
  @include field-label-red;
}
.pseudo-field-user-program-affiliation {
  .field-label {
    @include field-label-large;
  }
  .field-item {
    line-height: 22px;
    a {
      @include field-label-red;
    }
  }
  .pseudo-user-program-affiliation-title {
    font-size: 13px;
  }
}
.field-name-field-related-resources-hidden {
  display: none;
  margin: 0;
  padding: 0;
  ~ .description,
  ~ .action-links {
    display: none;
  }
}
