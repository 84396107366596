#block-responsive-menu-toggle {
  display: block;
  padding-right: 5px;

  @include breakpoint($screen-s) {
    padding-right: 25px;
  }
  @include breakpoint($screen-mobile-nav) {
    display: none;
  }
  .label {
    display: none;
  }
}

.responsive-menu-toggle-icon {
  &:before,
  &:after,
  span.icon {
    height: 5px;
    width: 35px;
    border-radius: 10px;
    background: $color-red;
  }
  &:before {
    top: 1px;
  }
  &:after {
    top: 27px;
  }
}


.mm-opened {
  .responsive-menu-toggle-icon {
    &:before,
    &:after {
      top: 14px;
    }
  }
}

#horizontal-menu,
.menu {
  > li > a.main-menu-item-donate-link {
    margin-top: -2px;
    padding: 10px 23px;
    background-color: $color-purple;
    border-radius: 10px;
    color: $color-white;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.76px;
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
      background-color: $color-orange-dark;
      color: $color-white;
    }
  }
}

#horizontal-menu {
  display: flex;
  justify-content: space-around;
  line-height: 1.25;
  > li {
    cursor: pointer;
    padding-bottom: 42px;
    margin-bottom: -42px;
    text-align: center;
    > a {
      margin-top: 5px;
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: 1.25px;
    }
    > a.main-menu-item-donate-link {
      padding: 10px 30px;
      background-color: $color-orange-dark;
      border-radius: 4px;
      font-size: 14px;
      &:hover {
        background-color: $color-purple;
      }
    }
  }
  > li.first {
    // This is the search block used on the mobile nav, which we don't need
    // for the desktop version.
    display: none;
  }
  ul {
    width: 200px;
    margin-left: -100px;
    padding: 5px 25px;
    left: 50%;
    box-shadow: 0px 0px 10px 0px $color-black-24;
    background-color: $color-white;
    text-align: left;
    &:before {
      content: "";
      position: absolute;
      height: 20px;
      width: 120%;
      top: -20px;
      left: -10%;
      background-color: $color-white;
    }

    li {
      padding: 0px 0px 15px;
      line-height: 20px;
    }
    a {
      padding: 0px;
      font-weight: 600;
      letter-spacing: 1px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  li {
    flex: 0 0 auto;
    @include breakpoint($screen-mobile-nav) {
      display: inline-block;
    }

    a {
      color: $color-black;
      text-decoration: none;
      font-size: 14px;
    }
    a:hover {
      color: $color-purple;
    }
  }
}

#off-canvas-menu {
  .block-esyp-search-esyp-search-block {
    display: block;

    #esyp-search-form,
    #esyp-search-form--2 {
      position: relative;
      width: 95%;
    }
    .form-text {
      box-sizing: border-box;
      width: 100%;
    }
    .form-submit {
      top: -4px;
      right: 12px;
    }
  }
}
