.block-esyp-cta {
  height: $cta-height;
  overflow: hidden;
  display: none;
  background-color: $color-green-light;
  color: $color-green-dark;

  .content {
    height: calc(#{$cta-height} - 20px);
    max-width: 970px;
    margin: 0 auto;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    @include breakpoint($screen-s) {
      padding: 10px 20px;
    }
    @include breakpoint($screen-m) {
      flex-direction: row;
    }
  }
}
.cta__main {
  flex: 0 1 auto;
  text-align: center;
  @include breakpoint($screen-m) {
    margin-right: 45px;
    text-align: left;
  }
}
.cta__secondary {
  width: 146px;
  flex: 0 0 auto;
  text-align: center;
}
.cta__super_heading {
  font-family: $font-maitree;
  font-size: 16px;
  line-height: 1.2;
  @include breakpoint($screen-m) {
    font-size: 20px;
  }
}
.cta__heading {
  font-size: 24px;
  line-height: 1;
  font-weight: 900;
  margin: 0px;
  @include breakpoint($screen-m) {
    font-size: 38px;
  }
  @include breakpoint($screen-l) {
    font-size: 46px;
  }
  @include breakpoint($screen-xl) {
    font-size: 48px;
  }
}
.cta__content {
  margin-top: 5px;
  font-family: $font-maitree;
  @include breakpoint($screen-m) {
    margin-top: 16px;
  }
  p {
    font-size: 15px;
    line-height: 1.2;
    margin-bottom: 5px;
    @include breakpoint($screen-s) {
      font-size: 16px;
      margin-bottom: 10px;
    }
    @include breakpoint($screen-m) {
      line-height: 24px;
      font-size: 18px;
    }
  }
}
.cta__super_callout_text {
  font-size: 13px;
  line-height: 1;
  font-weight: 800;
  @include breakpoint($screen-m) {
    font-size: 14px;
  }
}
.cta__callout_text {
  margin: 2px 0px 5px 0px;
  font-size: 24px;
  line-height: 1;
  letter-spacing: 2px;
  font-family: $font-oswald;
  font-weight: 600;
  color: $color-green-light;
  text-shadow:
    -1px -1px 0 $color-green-dark,
    1px -1px 0 $color-green-dark,
    -1px 1px 0 $color-green-dark,
    1px 1px 0 $color-green-dark;
  @include breakpoint($screen-m) {
    margin: 0px 0px 8px 0px;
    font-size: 66px;
  }
}
.cta__button {
  @include button;
  display: block;
  margin: 0 auto;
  padding: 4px;
  background-color: $color-green-dark;
  font-size: 13px;
  font-weight: 800;
  &, &:link, &:hover, &:active, &:visited {
    color: $color-white;
  }
  @include breakpoint($screen-s) {
    padding: 6px;
  }
  @include breakpoint($screen-m) {
    padding: 10px;
  }
}
.cta__close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 12px;
  text-decoration: none;
  border-bottom: solid 1px $color-gray;
  &, &:link, &:hover, &:active, &:visited {
    color: $color-gray;
  }
}
