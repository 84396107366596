.view-video-cards,
.view-paragraph-video-cards {
  @include view-masonry-grid;

  .views-masonry-grid-sizer,
  .views-row {
    @include breakpoint($screen-l) {
      width: 33.333%;
    }
  }

  .file-video {
    margin-bottom: 15px;
  }

  .field-name-field-readings {
    .field-items {
      a,
      a:hover,
      a:visited {
        text-decoration: none;
      }
    }
  }
}
