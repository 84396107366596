h2,
%h2 {
  font-size: 28px;
}
h3,
%h3 {
  color: $color-red;
  font-family: $font-maitree;
  font-size: 25px;
  font-weight: 600;
  line-height: 27px;
}
h5 {
  color: $color-red;
  font-family: $font-muli;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 0;
}
h6,
%h6 {
  color: $color-red;
  font-family: $font-muli;
  font-size: 10px;
  font-weight: 800;
  margin: 0;
  margin-bottom: 5px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
#{$block-level-elements-basic} {
  margin-top: 0;
  margin-bottom: 30px;
}
hr {
  height: 4px;
  background-color: $color-red;
  border: none;
  position: relative;
}
a {
  &.button {
    @include button;
  }
  &.download {
    align-items: center;
    display: flex;
    font-family: $font-muli;
    font-size: 14px;
    font-weight: bold;
    &:hover:before {
      background-image: url('../images/download-hover.svg');
    }
    &:before {
      background-image: url('../images/download.svg');
      background-size: 30px;
      background-repeat: no-repeat;
      content: '';
      display: inline-block;
      height: 30px;
      padding-right: 15px;
      width: 30px;
      transition: all .2s ease-in-out 0s;
    }
  }
}
.indented {
  margin-left: 18px;
  margin-top: 5px;
}
ul {
  padding-left: 15px;
  &.checkboxes {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      @include checkboxes;
    }
  }
  &.dashes {
    margin: 0;
    list-style-type: none;
    li {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        margin-left: -18px;
        height: 2px;
        width: 9px;
        top: 12px;
        background-color: $color-red;
      }
    }
  }
  &.arrows, &.red-dots, &.purple-dots { //We had to override the previous arrow styling for usability issues, and then we changed all the bullets to purple
    list-style-type: none;
    li::before {
      content: "\2022";
      color: $color-purple;
      display: inline;
      position: absolute;
      width: 1em;
      margin-left: -17px;
    }
  }
}
ol {
  padding: 0;
  margin-left: 12px;
  li {
    line-height: 1.8;
    padding-left: 5px;
  }
}

.dotted-columns {
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .column {
    display: flex;
    flex-direction: column;
    width: 200px;
    background-image: linear-gradient($color-red 33%, rgba(255,255,255,0) 0%);
    background-position: right;
    background-size: 1px 5px;
    background-repeat: repeat-y;
    &:last-child {
      background: none;
    }
    .cell {
      font-size: 12px;
      padding: 5px 10px;
      text-align: center;
    }
  }
}

.three-columns {
  padding: 10px 0;
  .column {
    margin-bottom: 18px;
  }
  @include breakpoint($screen-m) {
    display: flex;
    .column {
      display: flex;
      flex-direction: column;
      width: 30%;
      padding-right: 15px;
      &:last-child {
        padding-right: 0;
      }
    }
  }

  td.cell {
    font-size: 12px;
  }
}
.voice-prompt {
  color: $color-red;
  font-style: italic;
  font-size: 16px;
}
