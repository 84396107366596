.cycle-prev,
.cycle-next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: 40px;
  height: 40px;
  background-size: 40px 40px;
  z-index: 999;
}
.cycle-prev {
  left: 15px;
  background: url('../images/slider-left.svg') 0px 0px no-repeat;
}
.cycle-next {
  right: 15px;
  background: url('../images/slider-right.svg') 0px 0px no-repeat;
}
.cycle-controls-hidden {
  @include screen-reader-only;
}
