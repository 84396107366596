.pledge-webform {
  .webform-client-form {
    padding: 25px 0;
    @include breakpoint($screen-m) {
      padding: 25px 50px;
    }
  }
  .webform-component-markup,
  .webform-confirmation .thank-you-title {
    display: inline-block;
    margin-top: 50px;
    font-size: 40px;
    font-weight: 400;
    font-family: $font-maitree;
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }
  .form-item {
    margin: 0;
  }
  input[type="text"],
  input[type="email"],
  textarea.form-textarea,
  .chosen-single,
  .form-select {
    box-sizing: border-box;
    border: none;
    height: 50px;
    width: 100%;
    padding: 0 20px;
    font-size: 11px;
    font-weight: 700;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  textarea.form-textarea {
    margin-bottom: 30px;
    padding: 32px;
    height: 150px;
  }
  .webform-component-civicrm-contact {
    padding: 0;
    .token-input-list {
      border: none;
    }
    input {
      background-color: $color-gray-lighter;
      width: 100% !important;
    }
  }
  .form-select.civicrm-enabled {
    position: relative;
    width: 100%;
    &.state {
      border-radius: 0px;
      -webkit-appearance: none;
      -webkit-border-radius: 0px;
      background: $color-gray-lighter url('../images/black_arrow_down.png') no-repeat 85% center;
      background-size: 7px;
    }
    &.chosen-processed {
      width: 100% !important;
      border: none;
    }
  }

  .webform-component-textarea label {
    font-family: $font-maitree;
    text-transform: none;
    font-size: 20px;
    font-weight: 400;
  }
  .chosen-single {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    box-shadow: none;
    padding-left: 0;
    margin: 0;
    overflow: visible;
  }
  .chosen-container-single .chosen-single div b {
    background-position: 0px 23px;
    margin-left: 15px;
  }
  .chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: -16px 23px;
    margin-left: 15px;
  }
  .chosen-container-single .chosen-drop {
    left: 0;
    .chosen-search-input {
      padding: 0;
      height: 25px;
    }
  }
  select {
    font-family: $font-muli;
    height: 55px;
  }

  .flex-box.state-city {
    display: flex;
    .city-field {
      width: 90%;
      padding-right: 20px;
    }
  }

}
