.paragraphs-item-accordion {
  @extend %paragraph-one-col-with-gutter;
  margin-bottom: 30px;
  .field-name-field-title {
    @extend h5;
    margin-bottom: 0;
    margin-top: 5px;
    padding-bottom: 5px;
  }
}

.paragraphs-item-accordion.red-border {
  .field-name-field-accordion-heading {
    color: $color-red;
    font-family: $font-maitree;
  }
  .field-collection-item-field-accordion-item {
    background: none;
    border-top: 3px solid $color-red;
    &::before {
      top: 12px;
      left: -40px;
      width: 20px;
      height: 28px;
      background-image: url('../images/arrow.png');
      background-size: 18px;
    }
    .field-name-field-accordion-heading {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .field-collection-item-field-accordion-item:last-child {
    border-bottom: 3px solid $color-red;
  }

}
