.view-progression-list .views-table {
  width: 100%;
  margin-bottom: 0;

  &:first-child thead {
    display: table-header-group;
  }
  thead {
    display: none;
    @extend %horizontal-polka-dots;
    background-image: linear-gradient($color-gray-medium 10%, rgba(255,255,255,0) 0%), linear-gradient($color-gray-medium 10%, rgba(255,255,255,0) 0%);
    background-position: center bottom, center top;
    tr {
      height: 40px;
    }
  }
  th {
    text-transform: uppercase;
    color: $color-red;
    font-size: 11px;
    border: none;
  }
  a:hover {
    color: $color-red;
  }
  td {
    padding: 5px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  tbody {
    border: none;
    th, td {
      vertical-align: top;
    }
    tr {
      background: none;
      border: none;
    }
  }
  .progression-grouping {
    color: $color-red;
    letter-spacing: 1px;
  }
  .views-field-field-progression-lesson- {
    width: 5%;
  }
  .views-field-title {
    width: 35%;
    a {
      text-decoration: none;
      font-weight: 600;
      &:hover {
        color: $color-purple;
      }
    }
  }
  .views-field-field-place-of-learning {
    width: 15%;
  }
  .views-field-field-grade-level-select {
    width: 10%;
  }
  .views-field-field-key-concepts {
    width: 35%;
  }
}
