body {
  margin: 0px;
}
.one-sidebar {
  .l-main-wrapper {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    @include breakpoint($screen-m) {
      flex-direction: row;
    }
  }
  #sidebar-first {
    order: 1;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 25px;
    @include breakpoint($screen-m) {
      width: 25%;
      padding-left: 10px;
      padding-right: 30px;
      padding-top: 100px;
    }
  }
  .l-content-wrapper {
    order: 2;
    @include breakpoint($screen-m) {
      width: 80%;
      padding-right: 30px;
    }
  }
}
.page-search,
.page-resource-search {
  #sidebar-first {
    background-color: $color-white;
    padding-right: 0;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
    padding-bottom: 18px;
    @include breakpoint($screen-m) {
      margin-top: 0;
      padding-top: 40px;
      padding-bottom: 0;
      width: 25%;
      margin-right: 2%;
    }
  }
  .l-content-wrapper {
    margin-left: 15px;
    margin-right: 15px;
    @include breakpoint($screen-m) {
      width: 73%;
      padding-right: 30px;
    }
  }
}
.messages-wrapper {
  max-width: 1200px;
  margin: 15px;
  padding-top: 15px;
}
