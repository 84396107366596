.paragraphs-item-donate-form {
  background-size: 0;
  background-repeat: no-repeat;
  background-color: $color-red;
  margin-bottom: 2px;
  padding-top: 20px;
  @include breakpoint($screen-m) {
    background-size: cover;
    background-color: transparent;
    margin-bottom: 2em;
  }
  > .content {
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include breakpoint($screen-m) {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 710px;
      padding-top: 20px;
      border-top: $color-white solid 5px;
    }
  }
  &:after {
    @include breakpoint($screen-m) {
      @include image-crimp(30px, 0px, $color-gray-lighter 30%, transparent 25%);
    }
  }
  .field-name-field-title {
    color: $color-white;
    font-size: 30px;
    line-height: 36px;
    font-weight: 900;
    padding: 0px 15px 5px 15px;
    @include breakpoint($screen-m) {
      width: calc(40% - 15px);
      padding-bottom: 20px;
      padding-left: 0px;
    }
    @include breakpoint($screen-l) {
      font-size: 50px;
      line-height: 60px;
    }
  }
  .field-name-field-orra-subtitle {
    display: none;
    color: $color-white;
    padding: 0px 15px 5px 15px;

    @include breakpoint($screen-s) {
      display: block;
      font-size: 24px;
      line-height: 28px;
      font-weight: lighter;
      font-family: $font-maitree;
    }
    @include breakpoint($screen-m) {
      font-size: 35px;
      line-height: 1.3;
      width: 60%;
      padding: 0;
      .field-item {
	padding-right: 20px;
      }
    }
  }

  .field-name-field-donation-form {
    margin-top: 20px;
    min-height: 800px;
    width: 425px;
    max-width: 100%;
    iframe {
      width: 100%;
    }
    .donation-widget.tabs {
      width: 100%;
      max-width: none;
    }
  }
}

.donation-form-footer {
  padding: 20px;
  background-color: $color-white;
  color: $color-gray-slate;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}