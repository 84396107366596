.view-blog,
.view-taxonomy-term {
  max-width: 1200px;
  margin: 0px auto;
  padding: 0px 15px 15px;
  .view-content {
    display: flex;
    flex-wrap: wrap;
  }
  .views-row {
    display: flex;
    @include breakpoint($screen-s) {
      width: calc(100% / 2);
    }
    @include breakpoint($screen-m) {
      width: calc(100% / 3);
    }
  }
  .pager {
    padding-top: 35px;
  }
}

.featured-blog-post {
  padding: 0px;
  * {
    box-sizing: border-box;
  }
  .views-row {
    width: 100%;
    .node-blog-post {
      width: 100%;
    }
    @include breakpoint($screen-m) {
      .post-type-event {
        .field-name-field-blog-image {
          display: block;
          width: calc((100% / 3) * 2);
          img {
            display: block;
          }
        }
        .field-pseudo-blog-teaser-card-image {
          display: none;
        }
      }
      .content {
        display: flex;
        position: relative;
      }
      .field-pseudo-blog-post-type-icon {
        position: absolute;
        height: auto;
        width: auto;
        margin: 0px;
        top: 50px;
        right: calc((100% / 6) - 15px);
        background-color: transparent;
        .esyp-blog-post-type-icon {
          width: 35px;
          margin-top: 0px;
        }
      }
      .field-pseudo-blog-teaser-card-image {
        width: calc((100% / 3) * 2);
        padding-bottom: 0px;
      }
      .group-blog-card-content-outer-wrapper {
        display: block;
        width: calc(100% / 3);
      }
      .field-name-field-post-type {
        position: absolute;
        top: 0px;
        right: 0px;
      }
      .group-blog-card-content-inner-wrapper {
        padding: 80px 35px 35px;
      }
      .field-pseudo-blog-post-featured-teaser-text {
        display: block;
        margin-bottom: 0px;
        font-size: 15px;
        letter-spacing: 0.5px;
        line-height: 26px;
      }
      .field-name-body {
        display: none;
      }
    }
  }
}
