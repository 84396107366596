.node-blog-post {
  display: flex;
  justify-content: center;
  > .content {
    width: 760px;
    padding: 0px 20px;

    @include breakpoint($screen-m) {
      padding: 0px 30px;
    }
  }

  .field-pseudo-blog-post-date {
    padding: 50px 0px 35px 0px;
    color: $color-purple;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    span {
      border-bottom: solid 1px $color-purple;
      padding-bottom: 5px;
    }
  }
  &.post-type-event .field-pseudo-blog-post-date {
    padding: 0px;
    span {
      display: none;
    }
  }

  .field-name-field-blog-image {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .field-name-title-field {
    padding-bottom: 28px;

    h1 {
      font-family: $font-maitree;
      font-size: 30px;
      font-weight: 300;
      text-align: center;
      margin: 50px 0px 25px 0px;

      @include breakpoint($screen-m) {
        font-size: 34px;
      }
    }
    &::after {
      content: '';
      border-bottom: solid 2px $color-gray;
      display: block;
      width: 12px;
      margin: 0 auto;
    }
  }
  .field-name-field-blog-guest-author {
    font-family: $font-maitree;
    text-align: center;
    font-size: 16px;
  }
  .field-name-field-blog-guest-author-profile {
    color: $color-purple;
    text-align: center;
    font-size: 11px;
    padding-top: 15px;
  }
  .field-name-body {
    font-family: $font-maitree;
    font-size: 16px;
    padding: 25px 0px 20px;
    @include dotted-border(bottom, $color-gray-light);

    @include breakpoint($screen-m) {
      font-size: 18px;
    }

    p:first-child:first-letter {
      color: $color-purple;
      font-size: 98px;
      line-height: 90px;
      float: left;
      font-weight: 200;
      padding: 0px 10px 0px 3px;
    }

    h3, h6 {
      color: $color-purple;
    }
    h5 {
      color: $color-purple;
      font-family: $font-maitree;
      font-size: 18px;
      font-weight: 600;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .media-float-left {
      @include breakpoint($screen-m) {
        margin-left: -150px;
      }
    }
    .media-float-right {
      @include breakpoint($screen-m) {
        margin-right: -150px;
      }
    }
  }
  .field-name-field-blog-tags {
    padding-top: 30px;
    padding-bottom: 50px;
    border-top: 1px solid $color-purple;
    font-size: 14px;
    .field-label {
      padding-bottom: 10px;
      color: $color-purple;
    }
  }
  .blog-post-pager {
    @include dotted-border(top, $color-gray-light);
    display: flex;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 30px 0px;
    clear: both;

    .prev-post, .next-post {
      @extend %prev-next-pager-wrapper;
    }
    .prev-post {
      @extend %prev-next-pager-prev-link;
    }
    .next-post {
      @extend %prev-next-pager-next-link;

      margin-left: auto;
      span, a {
        @include breakpoint($screen-m) {
          padding-right: 50px;
        }
      }
    }
  }
}
