$orra-el-height: 65px;
$orra-vertical-spacing: 8px;

.orraFormWrapper {
  display: inline-block;
  padding: 40px 20px;
  background: $color-white;
  letter-spacing: .04em;
  text-align: center;

  @include breakpoint($screen-s) {
    padding: 60px 40px;
  }

  @include breakpoint($screen-m) {
    padding: 80px 100px;
  }

  h2, h3 {
    color: $color-gray;
  }

  .orraForm,
  .orraDonorFields,
  .orraIframe {
    max-width: 520px;
  }

  #iframe_holder {
    margin: 0 auto;
    width: 100%;
    @include breakpoint($screen-s) {
      width: 400px;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;
    &:-webkit-inner-spin-button,
    &:-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  button,
  input,
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    border: 0;
  }

  button,
  input,
  label {
    transition: all .2s ease-in-out 0s;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      margin-top: $orra-vertical-spacing;
      margin-bottom: $orra-vertical-spacing;
      height: $orra-el-height;
    }

    input,
    label {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    label {
      z-index: 90;
      border-radius: 5px;
    }

    input[type="radio"] {
      opacity: 0.011;
      z-index: 100;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .amount {
    li {
      width: 160px;
    }

    label {
      color: $color-white;
      font-size: 26px;
      line-height: 64px;
    }

    input[type="radio"] {
      + label {
	background: $color-green-dark;
      }

      &:active + label,
      &:hover + label,
      &:checked + label {
	background: $color-green-light;
      }
    }
  }

  .frequencyWrapper,
  .tributeWrapper {
    ul {
      justify-content: center;
    }
    li {
      margin-right: 10px;
      margin-left: 10px;
    }

    label {
      text-transform: uppercase;
    }

    input[type="radio"] {
      + label {
	background: $color-gray-lighter;
	font-size: 13px;
      }

      &:active + label,
      &:hover + label,
      &:checked + label {
	color: $color-white;
      }
    }
  }

  .tributeWrapper {
    h3 {
      margin-top: 1.5em;
      margin-bottom: .5em;
      font-size: 16px;
    }
    label {
      line-height: 62px;
    }
    input[type="text"],
    li {
      width: 44%;
      @include breakpoint($screen-s) {
        width: 40%;
      }
    }
    input[type="radio"] {
      &:active + label,
      &:hover + label,
      &:checked + label {
	background: $color-gray-slate;
      }
    }
  }

  .frequencyWrapper {
    padding-bottom: 14px;
    input[type="text"],
    li {
      height: $orra-el-height/1.5;
      width: 40%;

      @include breakpoint($screen-s) {
        width: 20%;
      }
    }
    input[type="radio"] {
      &:active + label,
      &:hover + label,
      &:checked + label {
	background: $color-green-light;
      }
    }
    label {
      line-height: 40px;
    }
  }

  .selectWrapper {
    position: relative;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    &::after {
      content: '\2304';
      position: absolute;
      right: $orra-vertical-spacing * 2;
      color: #3c3c3c;
      font-size: 2em;
      pointer-events: none;
    }
  }

  .otherAmountWrapper {
    position: relative;
  }

  .otherAmount {
    &::before {
      content: '$';
      position: absolute;
      left: $orra-vertical-spacing * 2;
      top: $orra-vertical-spacing * 3.5;
      color: #919195;
      pointer-events: none;
    }
  }

  .twoCol {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    input[type="text"] {
      width: 48%;
    }
  }

  .tributeHonoreeEmailAcknowledgement {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: no-wrap;
    align-items: center;
  }

  select {
    cursor: pointer;
  }

  select,
  input[type="email"],
  input[type="number"],
  input[type="text"],
  input[type="textarea"] {
    display: inline-block;
    box-sizing: border-box;
    height: $orra-el-height;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    font-weight: 700;
  }

  button,
  input:not([type="radio"]),
  .selectWrapper {
    margin-top: $orra-vertical-spacing;
    margin-bottom: $orra-vertical-spacing;
    background: $color-gray-lighter;
  }

  button,
  input:not([type="radio"]),
  select {
    border: 1px solid $color-gray-lighter;
  }

  .checkbox {
    label {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 16px;
      line-height: 18px;
      text-transform: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-weight: 500;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
          &~ .checkmark-custom {
            background-color: $color-gray-light;

            &:after {
              display: block;
            }
          }
        }
      }

      .checkmark-custom {
        position: absolute;
        top: calc(50% - 12px);
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;

        &:after {
          content: "";
          position: absolute;
          display: none;

          left: 9px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
        }
      }

    }
  }

  button {
    margin: 0 8px;
    box-sizing: border-box;
    background: $color-red;
    border-radius: 5px;
    color: $color-white;
    cursor: pointer;
    text-transform: uppercase;

    &:disabled {
      background: $color-red-light;
    }
  }

  .errorWrapper,
  .successWrapper {
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .orraFooter {
    display: inline-block;
    margin-top: $orra-vertical-spacing;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .orraFooter {
    margin-top: 32px;
    color: $color-gray-light;
    font-weight: 700;
  }

  .titleWrapper,
  .orraIframe {
    h3 {
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 20px;
    }
  }

  button,
  input[type="submit"] {
    height: $orra-el-height + 6px;
    width: 45%;
    margin-top: $orra-vertical-spacing * 4;
    font-size: 18px;
    font-weight: 700;

    @include breakpoint($screen-s) {
      width: 170px;
    }

    &:hover {
      background: $color-purple;
    }
    &:disabled {
      &:hover {
	background: $color-red-light;
      }
    }
  }

  code {
    display: block;
    margin-bottom: 30px;
    color: $color-orange;
  }

  input,
  select {
    box-shadow: none;
    outline: none;
    &:focus {
      border: 1px solid $color-gray-medium;
    }
  }

  input {
    &.invalid,
    &:invalid,
    &.required,
    &:required {
      border: 1px solid $color-red;
    }
  }
  .successLink {
    @include button;
    transition: all .2s ease-in-out 0s;
    &:hover {
      background: $color-purple;
    }
  }

  .progressBar {
    margin: 0 auto;
    width: 100%;
    border-top: 2px solid $color-gray-light;
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @include breakpoint($screen-m) {
      width: 30%;
    }

    .indicator {
      margin-top: -8px;
      height: 10px;
      width: 10px;
      background: $color-white;
      border-radius: 10px;
      border: 2px solid $color-red;
    }
    .selected {
      margin-top: -13px;
      height: 20px;
      width: 20px;
      background: $color-red;
      border-radius: 30px;
    }
  }
}

.orraDebug {
  padding: 1em;
  background: $color-red;
  color: $color-white;
  font-weight: 700;
  text-align: center;
}

