@mixin crimping($height, $top_color, $bottom_color) {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  height: $height;
  background: linear-gradient(45deg, $top_color 30%, $bottom_color 25%) 0 50%,
    linear-gradient( -45deg, $top_color 30%, $bottom_color 25%) 0 50%;
  background-repeat: repeat-x;
  background-size: 15px 32px, 15px 32px;
}

@mixin image-crimp($height, $bottom, $top-color, $bottom-color) {
  content: " ";
  display: block;
  position: relative;
  width: 100%;
  bottom: $bottom;
  height: $height;
  background: linear-gradient(45deg, $top-color, $bottom-color) 0 50%,
    linear-gradient(-45deg, $top-color, $bottom-color) 0 50%;
  background-repeat: repeat-x;
  background-size: 30px 30px, 30px 30px;
}

@mixin dotted-border($border-type, $second-bg-img:false, $second-bg-img-position:false, $second-bg-img-size:false, $second-bg-img-repeat:false) {
  $dot-bg-position: bottom left;
  $dot-bg-url: url(../images/dash-single.svg);
  $dot-bg-size: 6px 2px;
  @if ($border-type == 'double') {
    $dot-bg-size: 6px 8px;
    $dot-bg-url: url(../images/dash-double.svg);
  }
  @if ($second-bg-img == false) {
    background-image: $dot-bg-url;
    background-position: $dot-bg-position;
    background-size: $dot-bg-size;
    background-repeat: repeat-x;
  }
  @else {
    background-image: $dot-bg-url, $second-bg-img;
    background-position: $dot-bg-position, $second-bg-img-position;
    background-size: $dot-bg-size, $second-bg-img-size;
    background-repeat: repeat-x, $second-bg-img-repeat;
  }
}

@mixin container-crimp() {
  position: relative;
  padding: 20px;
  @include breakpoint($screen-m) {
    padding: 20px 60px;
  }

  background-color: $color-white;
  &:before {
    @include crimping(30px, transparent, $color-gray-lighter);
    top: 0;
  }
  &:after {
    @include crimping(25px, $color-gray-lighter, transparent);
    bottom: 0;
  }
}
@mixin page-container-crimp {
  .l-content-wrapper {
    margin-top: 30px;
  }
  #content {
    @include container-crimp;
  }
}

@mixin page-title-with-border {
  border-top: 4px solid $color-red;
  color: $color-red;
  font-family: $font-maitree;
  font-size: 30px;
  padding-top: 20px;
  font-weight: 700;
  line-height: $heading-line-height;
}
@mixin page-title-with-border-bottom {
  color: $color-red;
  font-family: $font-maitree;
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 20px;
  border-bottom: 4px solid $color-red;
}
@mixin page-title-with-top-bottom-border {
  @include page-title-with-border;
  @include dotted-border(double);
  padding-bottom: 20px;
}
@mixin field-label-red {
  font-family: $font-muli;
  font-size: 10px;
  line-height: 22px;
  padding: 0;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  color: $color-red;
}
@mixin field-label-large {
  font-family: $font-maitree;
  font-size: 25px;
  line-height: 27px;
  font-weight: 500;
  color: $color-red;
  margin-top: 25px;
  margin-bottom: 15px;
}
@mixin field-with-label-heading {
  @include dotted-border(single);
  padding: 20px 0;
  > .field-label {
    @include field-label-large;
    margin-bottom: 20px;
  }
}
/* Cross-browser pretty select.
 * This needs a wrapper around the select element.
 */
@mixin form-select {
  position: relative;

  padding-top: 5px;
  padding-bottom: 5px;
  background: $color-white url('../images/dots.png') 0 100% repeat-x;

  select {
    font-family: $font-muli;
    padding: 4px 20px 4px 9px;
    appearance: none;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 1px;
    border: none;
    max-width: 100%;
    background: $color-white;
    &.is-empty {
      color: $color-gray-slate;
    }
  }
  /*
   * Creates an arrow next to the dropdown
   * Borders are mitred, so giving something a width: 0
   * and solid top border with transparent left and right
   * borders results in two solid mitred corners meeting
   * like this: \/.
   */
  &:after {
    content: " ";
    position: absolute;
    top: 17.5px;
    right: 5px;
    z-index: 2;
    pointer-events: none;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid $color-red;
    margin-top: -3px;
  }
}
@mixin button {
  display: inline-block;
  border: none;
  background-color: $color-red;
  border-radius: 5px;
  color: $color-white;
  padding: 10px 30px;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    background-color: $color-purple;
  }
}
@mixin button-slim {
  @include button;
  font-size: 12px;
  line-height: 12px;
  padding: 9px 14px;
}
@mixin paragraph-tag-reset {
  &:first-child:first-letter {
    color: inherit;
    float: none;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding-right: 0;
  }
}
@mixin view-masonry-grid($width: 50%) {
  .view-content {
    margin: 0 -8px;
    width: 100%;
  }
  .views-masonry-grid-sizer,
  .views-row {
    width: 100%;
    margin-bottom: 15px;
    box-sizing: border-box;
    @include breakpoint($screen-m) {
      width: $width;
      padding: 0 8px;
    }
  }
  .item-list {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    .pager {
      text-align: center;
      li {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
}

@mixin checkboxes {
  padding: 3px 0;
  padding-left: 15px;
  background: url('../images/checkboxes.png') 0 11px no-repeat;
  background-size: 10px 10px;
}

// see https://webaim.org/techniques/css/invisiblecontent/
@mixin screen-reader-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
