.program-map-filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  @include breakpoint($screen-l) {
    flex-wrap: nowrap;
  }
  h4 {
    display: inline-block;
    font: $font-maitree;
    font-size: 20px;
    line-height: 30px;
    color: $color-red;
    margin: 0;
    margin-right: 25px;
  }
  .form-text,
  .form-select {
    padding: 0;
    font-size: 16px;
    line-height: 20px;
    background-color: $color-white;
    border: none;
  }
  .form-type-textfield {
    height: 20px;
    position: relative;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: url('../images/dots.png') 0 100% repeat-x;
    /*
     * Creates an arrow next to the input
     * Borders are mitred, so giving something a width: 0
     * and solid top border with transparent left and right
     * borders results in two solid mitred corners meeting
     * like this: \/.
     */
    &:before {
      content: " ";
      position: absolute;
      top: 11px;
      left: 0;
      z-index: 2;
      pointer-events: none;
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 7px solid $color-red;
    }
  }
  .form-type-select {
    padding-top: 0;
    padding-bottom: 5px;
    height: 25px;
    background-color: transparent;
    border: none;
    &:after {
      top: 15px;
    }
  }
  .form-select {
    padding-top: 5px;
    height: 25px;
    padding-bottom: 0;
  }
  .program-map-filters-form-actions {
    margin-right: 0;
    margin-left: auto;
  }
  .button {
    @include button-slim;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
  }
}
.program-map-filters-form-field {
  margin-right: 20px;
  margin-bottom: 10px;
}
.program-map-filter__grade-level,
.program-map-filter__citystatezip,
.program-map-filter__country {
  width: 15%;
  min-width: 150px;
  .form-text {
    max-width: 100%;
  }
}
