.view-mode-teaser-node-type-resource-detail,
.view-mode-search-result {
  background-color: $color-white;
  position: relative;
  padding: 15px;
  margin-bottom: 20px;

  &.esyb-curriculum .field-name-title-field {
    margin-top: 10px;
  }

  .pseudo-esyb-curriculum {
    position: absolute;
    width: 100%;
    background-color: $color-purple;
    color: $color-white;
    top: 0;
    left: 0;
    text-align: center;
    .field-label {
      font-size: 10px;
      line-height: 18px;
      font-weight: normal;
    }
  }

  .field-name-title-field,
  .node-title {
    margin-top: 0;
    margin-bottom: 10px;
    width: 100%;
    a {
      font-family: $font-maitree;
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: $color-black;
      text-decoration: none;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
  }

  .group-description {
    display: flex;
    padding-bottom: 5px;
  }

  .field-name-field-lesson-summary {
    padding-bottom: 10px;
    span {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .field-name-field-teaser-image,
  .field-name-field-program-logo-photo {
    display: none;
    width: 100px;
    height: 100px;
    padding: 6px 10px 0 0;
    flex-shrink: 0;
    overflow: hidden;
    @include breakpoint($screen-m) {
      display: block;
    }
    img {
      display: block;
    }
  }
}
