.logged-in.admin-menu {
  #header.is-scroll-header {
    margin-top: 29px;
  }
}

#header {
  background-color: $color-white;
  width: 100%;

  @include breakpoint($screen-mobile-nav) {
    height: $header-height;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: height 0.3s
  }

  .with-cta & {
    @include breakpoint($screen-mobile-nav) {
      position: absolute;
      top: $cta-height;
      box-shadow: 0px -10px 30px 0px rgba($color-green-dark, 0.4);
    }
  }

  &.is-scroll-header {
    @include breakpoint($screen-mobile-nav) {
      position: fixed;
      top: 0;
      height: auto;
      box-shadow: 0px 0px 10px 0px $color-black-24;
    }
    .region-header {
      height: auto;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    #horizontal-menu > li {
      padding-bottom: 13px;
      margin-bottom: -13px;
    }
  }

  .region-header {
    display: flex;
    height: $header-height;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    transition: all 0.3s;
    @include breakpoint($screen-mobile-nav) {
      justify-content: space-around;
    }
    @include breakpoint($screen-xxl) {
      padding-left: 75px;
      padding-right: 75px;
    }
  }
}

.block-menu {
  display: none;
  font-size: 10px;
  text-transform: capitalize;

  @include breakpoint($screen-mobile-nav) {
    display: block;
  }

  .menu {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      list-style-image: none;
      padding: 4px 0px;
      transition: all 0.3s;
    }
    .is-scroll-header & {
      li {
        padding: 2px 0px;
      }
    }
  }
}
.block-logo-block {
  padding-right: 0px;
  flex: 1;
  @include breakpoint($screen-s) {
    padding-left: 20px;
  }
  @include breakpoint($screen-mobile-nav) {
    flex: none;
    text-align: center;
  }
  @include breakpoint($screen-xxl) {
    padding-right: 75px;
  }


  a {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    width: 185px;
    margin: 0;
  }
  .is-scroll-header & {
    img {
      @include breakpoint($screen-mobile-nav) {
        width: 100px;
      }
    }
  }
}
.block-responsive-menu {
  .responsive-menu-block {
    display: none;
    @include breakpoint($screen-mobile-nav) {
      display: block;
    }
  }
}
.block-esyp-search-esyp-search-block {
  display: none;

  @include breakpoint($screen-mobile-nav) {
    display: block;
  }

  .form-item {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.block-menu-menu-mobile-donate {
  display: block;
  padding-left: 5px;
  padding-right: 5px;
  @include breakpoint($screen-s) {
    padding-right: 20px;
  }
  @include breakpoint($screen-mobile-nav) {
    display: none;
  }
}
