.view-supportee-programs {
  @include dotted-border(single);
  padding-bottom: 20px;
  .view-content {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: visible;
    min-height: 320px;
    .views-row {
      margin-left: 10px;
      margin-right: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
